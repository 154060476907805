import React from "react";
import StatusCard from "../components/tailwind/StatusCard";
import useFetch from "../hook/useFetch";
import { BASE_URL } from "../utils/constants";
import { connect } from "react-redux";
import PropsType from "prop-types";
import TrafficCard from "../components/tailwind/TrafficCard";
import SeriesChart from "../components/dashboard/SeriesChart";

var formatter = new Intl.NumberFormat("vi-VN", {
  style: "currency",
  currency: "VND",
});

var firstDay = new Date(new Date().getFullYear(), 0, 1);

function DashboardContainer(props) {
  const orderCount = useFetch(`${BASE_URL}order/count`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${props.token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  const userCount = useFetch(`${BASE_URL}user/count`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${props.token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  const productCount = useFetch(`${BASE_URL}product/count`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${props.token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  const orderSum = useFetch(
    `${BASE_URL}order/sum?startDate=${firstDay.toISOString()}`,
    {
      method: "get",
      headers: {
        Authorization: `Bearer ${props.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    }
  );

  const unpaidOrderSum = useFetch(`${BASE_URL}order/sum?status=1`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${props.token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  const orderByMonth = useFetch(`${BASE_URL}statistic/orderCount?year=2021`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${props.token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 mb-4">
            <StatusCard
              color="pink"
              icon="trending_up"
              title="Tổng số đơn hàng"
              amount={`${orderCount.data}`}
              percentageIcon="update"
              percentageColor="green"
              percentage=""
              date={"Just Updated"}
            />
            <StatusCard
              color="orange"
              icon="groups"
              title="Tổng số Users"
              amount={`${userCount.data}`}
              percentageIcon="update"
              percentageColor="red"
              percentage=""
              date={"Just Updated"}
            />
            <StatusCard
              color="purple"
              icon="poll"
              title="Tổng số sản phẩm"
              amount={`${productCount.data}`}
              percentageIcon="update"
              percentageColor="orange"
              percentage=""
              date={"Just Updated"}
            />
            <StatusCard
              color="blue"
              icon="paid"
              title="Tổng doanh thu"
              amount={`${formatter.format(orderSum.data.grossTotal)}`}
              percentageIcon="update"
              percentageColor="green"
              percentage=""
              date={"Just Updated"}
            />
          </div>
        </div>
      </div>

      <div className="px-3 md:px-8 h-auto">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 xl:grid-cols-5">
            <div className="xl:col-start-1 xl:col-end-4 px-4 mb-14">
              <SeriesChart data={orderByMonth.data} />
            </div>
            <div className="xl:col-start-4 xl:col-end-6 px-4 mb-14">
              <TrafficCard
                unpaidOrderSum={unpaidOrderSum.data}
                orderSum={orderSum.data}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.data.token,
  };
};

DashboardContainer.propTypes = {
  token: PropsType.string,
};

export default connect(mapStateToProps, null)(DashboardContainer);
