const FeedbackVM = (data) => {
  const returnData = Object.assign({}, data);

  returnData["link"] = data.meta.link;

  return returnData;
};

const FeedbackMV = (data) => {
  return {
    title: data.title,
    content: data.content,
    meta: { link: data.link },
    group: { id: data.groupId },
  };
};

export { FeedbackVM, FeedbackMV };
