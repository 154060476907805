import React, { useEffect, useState } from "react";
import Form, {
  ButtonItem,
  SimpleItem,
  RequiredRule,
  Label,
  GroupItem,
} from "devextreme-react/form";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../data/dictionary";
import {
  BASE_URL,
  COUPON_CUSTOMER_TYPE,
  COUPON_PRODUCT_TYPE,
  COUPON_TYPE,
  VERSION,
} from "../../utils/constants";
import { CouponMV, CouponVM } from "../../model/Coupon";
import PropsType from "prop-types";
import tranformer from "../../utils/transformer";
import useFetch from "../../hook/useFetch";
import ProductSelectBox from "../product/ProductSelectBox";
import ProductList from "../product/ProductList";

loadMessages(dictionary);
locale("vi");

var qs = require("qs");

function CouponForm({
  token,
  promotionId,
  couponId,
  hidePopup,
  refreshCoupons,
}) {
  const [coupon, setCoupon] = useState({
    isPercent: false,
    type: "All",
    products: [],
    groups: [],
    categories: [],
  });

  const categories = useFetch(`${BASE_URL}${VERSION}category`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  const groups = useFetch(`${BASE_URL}${VERSION}group`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  const payment = useFetch(`${BASE_URL}${VERSION}paymentMethod`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  const refreshCouponDetail = () => {
    const include = ["products", "categories", "groups"];
    fetch(
      `${BASE_URL}${VERSION}coupon/${couponId}?${qs.stringify({
        include,
      })}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((result) =>
      result.json().then((json) => {
        setCoupon(CouponVM(json.data));
      })
    );
  };

  useEffect(() => {
    if (!couponId) {
      setCoupon({
        isPercent: false,
        type: "All",
        products: [],
        groups: [],
        categories: [],
      });

      return;
    }

    refreshCouponDetail();
  }, [couponId]);

  const discountTypeOptions = {
    items: [
      { value: false, name: "Giảm tiền" },
      { value: true, name: "Giảm %" },
    ],
    valueExpr: "value",
    displayExpr: "name",
    onValueChanged: (args) =>
      setCoupon({
        ...coupon,
        discountType: args.value,
      }),
  };

  const typeOptions = {
    items: COUPON_TYPE,
    valueExpr: "value",
    displayExpr: "fullTxt",
    onValueChanged: (args) => setCoupon({ ...coupon, type: args.value }),
  };

  const productTypeOptions = {
    items: COUPON_PRODUCT_TYPE,
    valueExpr: "value",
    displayExpr: "name",
    onValueChanged: (args) => setCoupon({ ...coupon, productType: args.value }),
  };

  const categoriesSelectOptions = {
    items: categories.data,
    displayExpr: "name",
    searchEnabled: true,
    valueExpr: "id",
  };

  const groupsSelectOptions = {
    items: groups.data,
    displayExpr: "name",
    searchEnabled: true,
    valueExpr: "id",
  };

  const buttonOptions = {
    text: "Update",
    type: "success",
    useSubmitBehavior: true,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (couponId) {
      tranformer.fetchUpdate(`${BASE_URL}${VERSION}coupon/${couponId}`, {
        token,
        body: CouponMV(coupon),
        callback: () => {
          refreshCoupons();
          hidePopup();
        },
      });
    } else {
      tranformer.fetchCreate(
        `${BASE_URL}${VERSION}promotion/${promotionId}/coupons/create`,
        {
          token,
          body: CouponMV(coupon),
          callback: () => {
            refreshCoupons();
            hidePopup();
          },
        }
      );
    }
  };

  const paymentMethodEditorOptions = {
    items: payment.data,
    displayExpr: "name",
    valueExpr: "id",
  };

  const customerEditorOptions = {
    items: COUPON_CUSTOMER_TYPE,
    valueExpr: "value",
    displayExpr: "name",
  };

  const addProducts = (products) => {
    const _products = coupon.products;

    products.forEach((element) => {
      if (_products.findIndex((x) => x.id === element.id) === -1) {
        _products.push(element);
      }
    });

    setCoupon((prevState) => ({
      ...prevState,
      products: _products,
    }));
  };

  const removeProducts = (product) => {
    const _products = coupon.products.filter((x) => x.id !== product.id);
    setCoupon((prevState) => ({
      ...prevState,
      products: _products,
    }));
  };

  return (
    <React.Fragment>
      <form action="your-action" onSubmit={handleSubmit}>
        <Form
          formData={coupon}
          readOnly={false}
          showColonAfterLabel={true}
          colCount={2}
          showValidationSummary={true}
          validationGroup="customerData"
        >
          <GroupItem>
            <GroupItem>
              <SimpleItem dataField="code" editorType="dxTextBox">
                <Label text={formatMessage("code")} />
                <RequiredRule />
              </SimpleItem>

              <SimpleItem dataField="description" editorType="dxTextArea">
                <Label text={formatMessage("description")} />
              </SimpleItem>
            </GroupItem>

            <GroupItem>
              <SimpleItem
                dataField="isPercent"
                editorType="dxRadioGroup"
                editorOptions={discountTypeOptions}
              >
                <Label text={formatMessage("discountType")} />
              </SimpleItem>

              <SimpleItem
                dataField="discount"
                editorType="dxNumberBox"
                visible={!coupon.isPercent}
                editorOptions={{
                  format: { style: "currency", currency: "VND" },
                }}
              >
                <Label text={formatMessage("discountAmount")} />
              </SimpleItem>

              <SimpleItem
                dataField="discount"
                editorType="dxNumberBox"
                visible={coupon.isPercent}
                editorOptions={{ format: "#0%" }}
              >
                <Label text={formatMessage("discountPercent")} />
                <RequiredRule />
              </SimpleItem>

              <SimpleItem
                dataField="maxDiscount"
                editorType="dxNumberBox"
                visible={coupon.isPercent}
                editorOptions={{
                  format: { style: "currency", currency: "VND" },
                }}
              >
                <Label text={formatMessage("maxDiscount")} />
              </SimpleItem>
            </GroupItem>
          </GroupItem>

          <GroupItem caption="Giới hạn">
            <SimpleItem
              dataField="useLimit"
              editorType="dxNumberBox"
              helpText="số lượt sử dụng mã tối đa"
            >
              <Label text={formatMessage("useLimit")} />
            </SimpleItem>

            <SimpleItem
              dataField="limitOnCustomer"
              editorType="dxNumberBox"
              helpText="số lượt sử dụng mã tối đa của mỗi khách hàng"
            >
              <Label text={formatMessage("limitOnCustomer")} />
            </SimpleItem>

            <SimpleItem
              dataField="limitOnProduct"
              editorType="dxNumberBox"
              helpText="số lượt sử dụng mã tối đa trên mỗi sản phẩm"
            >
              <Label text={formatMessage("limitOnProduct")} />
            </SimpleItem>
          </GroupItem>

          <GroupItem caption="Điều kiện" colSpan={2}>
            <SimpleItem
              dataField="type"
              editorType="dxSelectBox"
              editorOptions={typeOptions}
            >
              <Label text={formatMessage("type")} />
            </SimpleItem>

            {/* IF type = OrderPrice */}
            <SimpleItem
              dataField="fromTotal"
              editorType="dxNumberBox"
              editorOptions={{
                format: { style: "currency", currency: "VND" },
              }}
              helpText="Giá trị đơn hàng tối thiểu để được khuyến mại"
              visible={coupon.type === "OrderPrice"}
            >
              <Label text={formatMessage("fromTotal")} />
            </SimpleItem>

            {/* IF type = Product */}
            <SimpleItem
              visible={coupon.type === "Product"}
              dataField="productType"
              editorType="dxSelectBox"
              editorOptions={productTypeOptions}
            >
              <Label text={"Loại sản phẩm"} />
            </SimpleItem>

            {/* IF productType = Individual */}
            <SimpleItem
              visible={
                coupon.type === "Product" && coupon.productType === "individual"
              }
              helpText="Chọn danh sách những sản phẩm được áp dụng khuyến mại"
            >
              {/* {coupon.products.map((item, index) => (
                <ProductItem
                  key={index}
                  data={item}
                  index={index}
                  removeAction={removeProducts}
                />
              ))} */}
              <ProductList
                data={coupon.products}
                removeAction={removeProducts}
              />
              <ProductSelectBox
                token={token}
                onValueChanged={addProducts}
                filter={{}}
              />
            </SimpleItem>

            {/* IF productType = Category */}
            <SimpleItem
              visible={
                coupon.type === "Product" && coupon.productType === "category"
              }
              dataField="categories"
              editorType="dxTagBox"
              editorOptions={categoriesSelectOptions}
            >
              <Label text={formatMessage("category")} />
              <RequiredRule />
            </SimpleItem>

            {/* IF productType = Group */}
            <SimpleItem
              visible={
                coupon.type === "Product" && coupon.productType === "group"
              }
              dataField="groups"
              editorType="dxTagBox"
              editorOptions={groupsSelectOptions}
            >
              <Label text={formatMessage("group")} />
            </SimpleItem>

            {/* IF type = Customer */}
            <SimpleItem
              visible={coupon.type === "Customer"}
              dataField="customerType"
              editorType="dxSelectBox"
              editorOptions={customerEditorOptions}
              helpText="Chọn danh sách những khách hàng được áp dụng khuyến mại"
            >
              <Label text={formatMessage("customerType")} />
            </SimpleItem>

            {/* IF type = PaymentMethod */}
            <SimpleItem
              visible={coupon.type === "PaymentMethod"}
              dataField="paymentMethodId"
              editorType="dxSelectBox"
              editorOptions={paymentMethodEditorOptions}
              helpText="Chọn phương thức thanh toán được áp dụng khuyến mại"
            >
              <Label text={formatMessage("paymentMethod")} />
            </SimpleItem>
          </GroupItem>

          <ButtonItem
            horizontalAlignment="center"
            colSpan={2}
            buttonOptions={buttonOptions}
          />
        </Form>
      </form>
    </React.Fragment>
  );
}

CouponForm.propTypes = {
  token: PropsType.string,
  promotionId: PropsType.number,
  couponId: PropsType.number,
  hidePopup: PropsType.func,
  refreshCoupons: PropsType.func,
};

export default CouponForm;
