import TextBox from "devextreme-react/text-box";
import React, { useEffect, useState } from "react";
import PropsType from "prop-types";

function VideoThumbnail({ value, setValue }) {
  const [imgUrl, setImgUrl] = useState("");

  useEffect(() => {
    setImgUrl(value);
  }, [value]);

  function onValueChanged(args) {
    setImgUrl(args.value);
    setValue(args.value);
  }

  return (
    <div>
      <TextBox defaultValue={value} onValueChanged={onValueChanged} />
      <img style={{ height: 50 }} src={`${imgUrl}`} />
    </div>
  );
}

VideoThumbnail.propTypes = {
  value: PropsType.string,
  setValue: PropsType.func,
};

export default VideoThumbnail;
