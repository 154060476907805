import React from "react";
import "../styles/profile.scss";
import { PAGE_NAME, PERMISSION } from "../utils/constants";
import { connect } from "react-redux";
import PropsType from "prop-types";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import ProfileCard from "../components/tailwind/ProfileCard";
import { updateProfile } from "../actions/userActions";
import UpdatePasswordForm from "../components/profile/UpdatePasswordForm";

const ProfileContainer = ({ user, token, updateProfile }) => {
  const permissions = user.permissions || {};
  console.log("permissions", permissions);

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 xl:grid-cols-5">
            <div className="xl:col-start-1 xl:col-end-4 px-4 mb-14">
              <div className="px-3 md:px-8">
                <div className="container mx-auto max-w-full">
                  <div className="px-4 mb-14">
                    <ProfileCard
                      user={user}
                      token={token}
                      updateProfile={updateProfile}
                    />
                  </div>
                </div>
              </div>

              <div className="px-3 md:px-8">
                <div className="container mx-auto max-w-full">
                  <div className="px-4 mb-14">
                    <UpdatePasswordForm token={token} />
                  </div>
                </div>
              </div>
            </div>

            <div className="xl:col-start-4 xl:col-end-6 px-4 mb-14">
              <div className="grid grid-cols-1 px-4 mb-16">
                <Card>
                  <CardHeader color="purple" contentPosition="left">
                    <div className="w-full flex items-center justify-between">
                      <h2 className="text-white text-2xl">Danh sách Quyền</h2>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="overflow-x-auto">
                      <table className="items-center w-full bg-transparent border-collapse">
                        <thead>
                          <tr>
                            <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                              Màn hình
                            </th>
                            <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                              Quyền
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(permissions).map((item) => (
                            <tr key={item}>
                              <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                {PAGE_NAME[item]}
                              </th>
                              <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                {permissions[item].map((item2) => (
                                  <span
                                    style={{ display: "block" }}
                                    key={item2}
                                    className="mr-4"
                                  >
                                    <i className="fas fa-check fa-sm text-orange-500 mr-2"></i>{" "}
                                    {PERMISSION[item2]}
                                  </span>
                                ))}
                              </th>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    token: state.auth.data.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProfile: (token, user) => {
      return dispatch(updateProfile(token, user));
    },
  };
};

ProfileContainer.propTypes = {
  user: PropsType.object,
  token: PropsType.string,
  updateProfile: PropsType.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
