import { TextBox } from "devextreme-react";
import TagBox from "devextreme-react/tag-box";
import React, { useState, useCallback, useEffect } from "react";
import PropsType from "prop-types";
import Box, { Item } from "devextreme-react/box";
import SelectBox from "devextreme-react/select-box";
import { CATEGORY_TYPE } from "../utils/constants";
import { CheckBox } from "devextreme-react/check-box";

const mockData = {
  type: "array", //"array", "number", "text"
  unit: "mm",
  title: "Loại",
  show: true,
  value: ["Loại 1"],
};

const KeyMultiValue = ({ data = mockData, index, onValueChange, metaKey }) => {
  const [title, setTitle] = useState(data.title);
  const [value, setValue] = useState(data.value);
  const [type, setType] = useState(data.type);
  const [unit, setUnit] = useState(data.unit);
  const [show, setShow] = useState(data.show);

  useEffect(() => {
    onValueChange({
      metaKey: metaKey,
      title: title,
      value: value,
      type: type,
      unit: unit,
      show: show,
    });
  }, [title, value, type, unit, show]);

  const onChangeTitle = useCallback((e) => {
    setTitle(e.value);
  }, []);

  const onChangeValue = useCallback((e) => {
    setValue(e.value);
  }, []);

  const onChangeType = useCallback((e) => {
    setType(e.value);
  }, []);

  const onChangeUnit = useCallback((e) => {
    setUnit(e.value);
  }, []);

  const onChangePublic = useCallback((e) => {
    setShow(e.value);
  }, []);

  console.log("data", data);

  return (
    <React.Fragment>
      <Box direction="row" align="center" width="100%">
        <Item ratio={0} baseSize={40}>
          {`${index + 1}. `}
        </Item>
        <Item ratio={1}>
          <SelectBox
            defaultValue={data.type}
            displayExpr="name"
            valueExpr="value"
            onValueChanged={onChangeType}
            items={CATEGORY_TYPE}
          />
        </Item>
        <Item ratio={0} baseSize={30} />
        <Item ratio={1}>
          <TextBox onValueChanged={onChangeTitle} value={title} />
        </Item>
        <Item ratio={0} baseSize={30} />
        <Item ratio={1}>
          <CheckBox
            text="Hiển thị"
            value={data.show}
            onValueChange={onChangePublic}
          />
        </Item>
        <Item ratio={0} baseSize={30} />
        <Item visible={data.type === "array"} ratio={6}>
          <TagBox
            onValueChanged={onChangeValue}
            defaultValue={data.value}
            acceptCustomValue={true}
          />
        </Item>

        <Item visible={data.type !== "array"} ratio={1}>
          <TextBox
            placeholder="đơn vị"
            defaultValue={data.unit}
            onValueChanged={onChangeUnit}
          />
        </Item>

        <Item visible={data.type !== "array"} ratio={5}></Item>
      </Box>
    </React.Fragment>
  );
};

KeyMultiValue.propTypes = {
  data: PropsType.object,
  index: PropsType.number,
  metaKey: PropsType.string,
  onValueChange: PropsType.func,
};

export default KeyMultiValue;
