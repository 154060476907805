import React from "react";
import ReactDOM from "react-dom";
// import "./index.css";
// import "./styles/dx-styles.scss";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import configureStore from "./configureStore";
import routes from "./routes";
import { checkAuth } from "./actions/authActions";
import { initLanguage } from "./actions/settingActions";
import { checkUser } from "./actions/userActions";
// Tailwind CSS Style Sheet
import "./assets/styles/tailwind.css";

const store = configureStore();
store.dispatch(checkAuth());
store.dispatch(checkUser());
store.dispatch(initLanguage());

const renderApp = (Component) => {
  ReactDOM.render(
    // <React.StrictMode>
    <Provider store={store}>
      <Component />
    </Provider>,
    // </React.StrictMode>,
    document.getElementById("root")
  );
};

const importTheme = async () => {
  let themeId = localStorage.getItem("_theme");
  if (!themeId) {
    themeId = "dx.light.css";
    localStorage.setItem("_theme", themeId);
  }

  await import(`devextreme/dist/css/${themeId}`);
  renderApp(routes);
};

importTheme();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
