import React from "react";
import { connect } from "react-redux";
import PropsType from "prop-types";
import {
  DataGrid,
  Column,
  Editing,
  Pager,
  Paging,
  Button,
  MasterDetail,
} from "devextreme-react/data-grid";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../data/dictionary";
import { BASE_URL, VERSION } from "../utils/constants";
import "devextreme-react/text-area";
import useCustomStore from "../hook/useCustomStore";
import { useHistory } from "react-router-dom";
import CouponsView from "../components/CouponsView";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";

const allowedPageSizes = [10, 20, "all"];
loadMessages(dictionary);
locale("vi");

const URL = `${BASE_URL}${VERSION}promotion`;
const promotionsData = useCustomStore(URL, {
  key: "id",
});

const PromotionContainer = (props) => {
  const history = useHistory();

  promotionsData.setOptions({
    token: props.token,
    include: ["createdUser", "coupons"],
    history: history,
  });

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift({
      location: "after",
      widget: "dxButton",
      locateInMenu: "auto",
      options: {
        icon: "add",
        onClick: () => history.push("/promotion/add"),
      },
    });
  };

  const onRowDblClick = (e) => {
    history.push(`/promotion/${e.key}`);
  };

  const editCoupon = (e) => {
    history.push(`/promotion/${e.row.key}`);
  };

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="purple" contentPosition="none">
                <div className="w-full flex items-center justify-between">
                  <h2 className="text-white text-2xl">Quản lý Mã Khuyến mại</h2>
                </div>
              </CardHeader>
              <CardBody>
                <DataGrid
                  id="id"
                  showBorders={true}
                  dataSource={promotionsData.store}
                  selection={{ mode: "single" }}
                  repaintChangesOnly={true}
                  allowColumnResizing={true}
                  remoteOperations={true}
                  onRowDblClick={onRowDblClick}
                  onToolbarPreparing={onToolbarPreparing}
                >
                  <Editing
                    refreshMode={"reshape"}
                    mode="popup"
                    useIcons={true}
                    allowAdding={false}
                    allowDeleting={true}
                    allowUpdating={true}
                  />

                  <Paging defaultPageSize={20} />
                  <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={"full"}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />

                  <Column dataField="name" caption={formatMessage("name")} />
                  <Column
                    dataField="createdUser.fullName"
                    caption={formatMessage("creator")}
                  />
                  <Column
                    dataField="description"
                    caption={formatMessage("description")}
                  />
                  <Column
                    dataField="active"
                    caption={formatMessage("active")}
                    dataType="boolean"
                  />
                  <Column
                    dataField="startAt"
                    caption={formatMessage("startAt")}
                    dataType="datetime"
                  />
                  <Column
                    dataField="endAt"
                    caption={formatMessage("endAt")}
                    dataType="datetime"
                  />
                  <Column
                    dataField="updatedAt"
                    caption={formatMessage("updatedAt")}
                    dataType="datetime"
                  />

                  <Column type="buttons">
                    <Button name="edit" onClick={editCoupon} />
                    <Button name="delete" />
                  </Column>

                  <MasterDetail enabled={true} component={CouponsView} />
                </DataGrid>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.data.token,
  };
};

PromotionContainer.propTypes = {
  token: PropsType.string,
};

export default connect(mapStateToProps, null)(PromotionContainer);
