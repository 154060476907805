import React from "react";
import { DataGrid, Column, Editing } from "devextreme-react/data-grid";
import PropsType from "prop-types";
import { BASE_URL, VERSION } from "../utils/constants";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import Card from "@material-tailwind/react/Card";
import CardBody from "@material-tailwind/react/CardBody";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../data/dictionary";
import tranformer from "../utils/transformer";
import VideoThumbnail from "./VideoThumbnail";

loadMessages(dictionary);
locale("vi");

const ProductVideoList = ({ videos, token, productId }) => {
  const insertRequest = (url, method, data) => {
    return fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        thumbnailUrl: data.thumbnailUrl,
        title: data.title,
        url: data.url,
      }),
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          notify(
            {
              message: json.result,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
          return json.data.videos.find((x) => x.title === data.title);
        }
        throw json.message;
      })
    );
  };

  const deleteRequest = (url) => {
    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          notify(
            {
              message: json.result,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
          return;
        }
        throw json.message;
      })
    );
  };

  const updateRequest = (url, value) => {
    return fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(value),
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          notify(
            {
              message: json.result,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
          return;
        }
        throw json.message;
      })
    );
  };

  const imgsList = new CustomStore({
    key: "id",
    load: () => videos,
    insert: (values) =>
      insertRequest(
        `${BASE_URL}${VERSION}product/${productId}/videos/create`,
        "POST",
        values
      ),
    remove: (key) => deleteRequest(`${BASE_URL}${VERSION}video/${key}`),
    update: (key, value) =>
      updateRequest(`${BASE_URL}${VERSION}video/${key}`, value),
  });

  const imgCellRender = (data) => {
    return <img style={{ height: 80 }} src={`${data.value}`} />;
  };

  const urlCellRender = (data) => {
    return <a href={data.value}>{data.value}</a>;
  };

  const urlSetCellValue = (newData, value) => {
    newData.url = value;
    newData.thumbnailUrl = tranformer.convertThumbnailYoutube(value);
  };

  const thumbnailEditCellRender = (cell) => {
    return (
      <VideoThumbnail
        value={cell.value}
        setValue={(value) => cell.setValue(value)}
      />
    );
  };

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24 mb-12">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardBody>
                <DataGrid
                  id="grid"
                  showBorders={true}
                  dataSource={imgsList}
                  selection={{ mode: "single" }}
                  repaintChangesOnly={true}
                >
                  <Editing
                    refreshMode={"reshape"}
                    mode="form"
                    useIcons={true}
                    allowDeleting={true}
                    allowUpdating={true}
                    allowAdding={true}
                  />

                  <Column dataField="id" editorOptions={{ readOnly: true }} />
                  <Column dataField="title" dataType="string" />
                  <Column
                    dataField="url"
                    cellRender={urlCellRender}
                    setCellValue={urlSetCellValue}
                  />
                  <Column
                    dataField="thumbnailUrl"
                    cellRender={imgCellRender}
                    editCellRender={thumbnailEditCellRender}
                  />
                  <Column
                    dataField="updatedAt"
                    caption={formatMessage("updatedAt")}
                    dataType="datetime"
                    editorOptions={{ readOnly: true }}
                  />
                </DataGrid>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

ProductVideoList.propTypes = {
  token: PropsType.string,
  videos: PropsType.array,
  productId: PropsType.number,
};

export default ProductVideoList;
