const dictionary = {
  en: {
    address: "Address",
    avatar: "Avatar",
    author: "Author",
    allProduct: "All Product",
    active: "Active",

    brand: "Brand",

    content: "Content",
    category: "Category",
    code: "Code",
    createdAt: "Created at",
    creator: "Creator",
    confirmPassword: "Confirm Password",
    customerType: "Customer Type",
    couponAmount: "Coupon Amount",

    description: "Description",
    discountAmount: "Discount Amount",
    discountPercent: "Discount Percent",
    discountType: "Discount Type",
    discount: "Discount",

    email: "Email",
    excerpt: "Excerpt",
    enable: "Enable",
    endAt: "End At",

    fullName: "FullName",
    fromTotal: "From Total",
    feedback: "Feedback",
    friendlyGroup: "Friendly Group",

    gender: "Gender",
    group: "Group",
    grossTotal: "Gross Total",
    grossRevenue: "Gross Revenue",

    hotline: "Hotline",

    isDefault: "is Default",
    isActive: "is Active",
    image: "Image",
    insurance: "Insurance",
    info: "Info",
    isPercent: "is Percent",
    isHomePage: "is HomePage",

    key: "Key",
    keyword: "Keyword",

    link: "Link",
    logo: "Logo",
    lang: "Language",

    limitOnCustomer: "Limit On Customer",
    limitOnProduct: "Limit On Product",

    machineType: "Machine Type",
    maxDiscount: "Max Discount",
    meta: "Meta",

    order: "Order",

    page: "Page",
    position: "Position",
    price: "Price",
    phone: "Phone",
    paymentMethod: "Payment Method",
    post: "Post",
    parent: "Parent",
    productIds: "Products",
    password: "Password",
    roleName: "Quyền",

    quantity: "Quantity",

    referenceGroup: "Reference Group",
    showDescription: "Show Description",
    reference: "Link",
    reply: "Reply",

    name: "Name",
    note: "Note",
    netTotal: "Net Total",
    newPassword: "New Password",
    netRevenue: "Net Revenue",

    title: "Title",
    thumbnail: "Thumbnail",
    type: "Type",
    toTotal: "To Total",

    strapType: "Strap Type",
    slug: "Slug",
    save: "Save",
    salePrice: "Sale Price",
    sku: "SKU",
    shippingTotal: "Shipping Total",
    status: "Status",
    startAt: "Start At",

    updatedAt: "updated at",
    userManual: "User Manual",
    username: "User Name",
    useLimit: "Limit Number",
    url: "URL",

    value: "Value",
  },
  vi: {
    address: "Địa chỉ",
    avatar: "Avatar",
    author: "Tác giả",
    allProduct: "Tất cả sản phẩm",
    active: "Kích hoạt",

    brand: "Thương hiệu",

    category: "Danh mục",
    content: "Nội dung",
    code: "Mã",
    createdAt: "Thời gian tạo",
    creator: "Người tạo",
    confirmPassword: "Xác nhận mật khẩu",
    customerType: "Đối tượng KH",
    couponAmount: "Khuyến mại",

    description: "Mô tả",
    discountAmount: "Số tiền giảm",
    discountPercent: "% giảm",
    discountType: "Loại giảm giá",
    discount: "Giảm",

    email: "Email",
    excerpt: "Trích đoạn",
    enable: "Enable",
    endAt: "Kết thúc",

    fullName: "Họ tên",
    fromTotal: "Từ",
    feedback: "Phản hồi",
    friendlyGroup: "Nhóm SP liên quan",
    isBought: "Đã mua hàng",

    gender: "Giới tính",
    group: "Nhóm",
    grossTotal: "Tổng tiền",
    grossRevenue: "Tổng tiền",

    hotline: "Hotline",

    isDefault: "Mặc định",
    image: "Ảnh",
    isActive: "Kích hoạt",
    insurance: "Bảo hành",
    info: "Thông tin",
    isPercent: "Giảm %",
    isHomePage: "Trang chủ",

    key: "Key",
    keyword: "Từ khóa",

    link: "Link",
    logo: "Logo",
    lang: "Ngôn ngữ",

    limitOnCustomer: "SL giới hạn /KH",
    limitOnProduct: "SL giới hạn /SP",

    machineType: "Loại máy",
    meta: "Meta",
    maxDiscount: "Giảm tối đa",

    name: "Tên",
    note: "Ghi chú",
    netTotal: "Thành tiền",
    newPassword: "Mật khẩu mới",
    netRevenue: "Thành tiền",

    order: "Thứ tự",

    page: "Trang",
    position: "Vị trí",
    price: "Giá",
    phone: "Số điện thoại",
    paymentMethod: "PTTT",
    post: "Bài viết",
    parent: "Cha",
    productIds: "Danh sách sản phẩm",
    password: "Mật khẩu",

    quantity: "Số lượng",

    referenceGroup: "Nhóm liên kết",
    showDescription: "Hiển thị mô tả",
    reference: "Link",
    reply: "Phản hồi",

    title: "Tiêu đề",
    thumbnail: "Thumbnail",
    type: "Loại",
    toTotal: "Đến",

    strapType: "Loại dây",
    slug: "Slug",
    save: "Lưu",
    salePrice: "Giá Sale",
    sku: "SKU",
    shippingTotal: "Khuyến mại",
    status: "Trạng thái",
    startAt: "Bắt đầu",

    updatedAt: "Cập nhật",
    userManual: "Hướng dẫn sử dụng",
    username: "Tên đăng nhập",
    useLimit: "Số lượng giới hạn",
    url: "Đường dẫn",

    value: "Giá trị",
  },
};

export default dictionary;
