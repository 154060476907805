const CommentVM = (data) => {
  const returnData = Object.assign({}, data);

  return returnData;
};

const CommentMV = (data) => {
  return {
    content: data.content,
    type: data.type || 1,
    rate: data.rate || 1,
    parent: data.parent,
    post: data.post,
    customerName: data.customerName || "Doseco Admin",
    customerPhone: data.customerPhone || "0888211322",
  };
};

export { CommentVM, CommentMV };
