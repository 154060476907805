import React, { useState } from "react";
import FileUploader from "devextreme-react/file-uploader";
import { BASE_URL, FILE_URL, VERSION } from "../utils/constants";
import PropsType from "prop-types";
import "../styles/image-uploader.scss";
import { useHistory } from "react-router-dom";
import { Button } from "devextreme-react/button";
import tranformer from "../utils/transformer";

const ProductImageUploader = ({ token, productId, refreshList }) => {
  const history = useHistory();
  const [imgList, setImgList] = useState([]);

  const getUploadedResponse = (e) => {
    const response = JSON.parse(e.request.response);

    const _imgList = [...imgList, response.data[0]];
    setImgList(_imgList);
  };

  const getUploadErrorResponse = (e) => {
    if (e.error.status === 401) {
      history.push("/expiredToken");
    }
  };

  const productImageUpload = () => {
    tranformer.fetchUpdate(
      `${BASE_URL}${VERSION}product/${productId}/images/connectMany`,
      {
        token,
        body: imgList.map((x) => {
          return { id: x.id };
        }),
        callback: () => {
          refreshList();
        },
      }
    );
  };

  return (
    <div className="fileuploader-container">
      <FileUploader
        multiple={true}
        accept={"image/*"}
        uploadHeaders={{
          Authorization: "Bearer " + token,
        }}
        name={"files"}
        uploadMode={"instantly"}
        onUploaded={getUploadedResponse}
        onUploadError={getUploadErrorResponse}
        uploadUrl={`${BASE_URL}upload/multiple`}
      />

      <div style={{ display: "inline-block" }}>
        {imgList.length > 0 &&
          imgList.map((item, index) => (
            <img
              style={{
                display: "inline-block",
                height: 150,
                objectFit: "contain",
              }}
              key={index}
              alt={""}
              src={`${FILE_URL}${item.path}`}
            />
          ))}
      </div>

      <div style={{ margin: 10, position: "relative", float: "right" }}>
        <Button
          icon="check"
          type="success"
          text="Thêm vào SP"
          onClick={productImageUpload}
        />
      </div>
    </div>
  );
};

ProductImageUploader.propTypes = {
  token: PropsType.string,
  defaultValue: PropsType.object,
  onImgUrlChange: PropsType.func,
  productId: PropsType.number,
  refreshList: PropsType.func,
};

export default ProductImageUploader;
