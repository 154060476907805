import React, { useState } from "react";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import PropsType from "prop-types";
import readXlsxFile from "read-excel-file";
import FileUploader from "devextreme-react/file-uploader";
import tranformer from "../../utils/transformer";
import { BASE_URL, VERSION } from "../../utils/constants";
import { ProductExcelMV } from "../../model/Product";
import SelectBox from "devextreme-react/select-box";
import useFetch from "../../hook/useFetch";
import slugify from "slugify";
import notify from "devextreme/ui/notify";

function ProductImportExcel({ visible, hidePopup, token, refreshList }) {
  const [importData, setImportData] = useState([]);
  const [categoryId, setCategoryId] = useState(null);

  const categories = useFetch(`${BASE_URL}${VERSION}category`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  const changeValue = (e) => {
    readXlsxFile(e.value[0]).then((rows) => {
      const keys = rows[1];

      let data = [];

      for (let index = 2; index < rows.length; index++) {
        const element = rows[index];

        const result = Object.assign(
          ...keys.map((k, i) => ({ [k]: element[i] }))
        );

        if (result.sku && result.name) data.push(result);

        if (!result.sku) {
          notify(
            {
              message: `Lỗi thiếu SKU sản phẩm ở dòng ${index + 1}`,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "error",
            10000
          );
          return;
        }
        if (!result.name) {
          notify(
            {
              message: `Lỗi thiếu tên sản phẩm ở dòng ${index + 1}`,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "error",
            10000
          );
          return;
        }
      }

      setImportData(data);
    });
  };

  const importProduct = () => {
    if (!categoryId) {
      notify(
        {
          message: "Vui lòng chọn danh mục",
          position: {
            my: "center top",
            at: "center top",
          },
        },
        "error",
        3000
      );
      return;
    }

    if (!importData || !importData.length) {
      notify(
        {
          message: "Vui lòng chọn file excel để import",
          position: {
            my: "center top",
            at: "center top",
          },
        },
        "error",
        3000
      );
      return;
    }

    const selectedCategory = categories.data.filter((cat) => cat.id === categoryId)[0]

    tranformer.fetchCreate(`${BASE_URL}${VERSION}product/batch`, {
      token,
      body: importData.map((item) => {
        delete item.brand;

        return ProductExcelMV({
          ...item,
          categoryId,
          type: selectedCategory.type,
          slug: slugify(`${item.name} ${item.sku}`, {
            replacement: "-", // replace spaces with replacement character, defaults to `-`
            remove: undefined, // remove characters that match regex, defaults to `undefined`
            lower: true, // convert to lower case, defaults to `false`
            strict: false, // strip special characters except replacement, defaults to `false`
            locale: "vi", // language code of the locale to use
          }),
        });
      }),
      callback: () => {
        refreshList();
        hidePopup();
      },
      errCallback: (err) => {
        if (err.code === "SKU_EXISTS") {
          (err?.errors?.sku || []).forEach((element) => {
            const _findIndex = importData.findIndex((x) => x.sku === element);

            notify(
              {
                message: `Lỗi trùng SKU sản phẩm ở dòng ${_findIndex + 3}`,
                position: {
                  my: "center bottom",
                  at: "center bottom",
                },
              },
              "error",
              10000
            );
          });
        }
        hidePopup();
      },
    });
  };

  return (
    <Popup
      visible={visible}
      onHiding={hidePopup}
      dragEnabled={false}
      hideOnOutsideClick={false}
      showCloseButton={true}
      showTitle={true}
      title="Import sản phẩm"
      container=".dx-viewport"
      width={600}
      height={400}
    >
      <div className="dx-field">
        <div className="dx-field-label">Chọn danh mục</div>
        <div className="dx-field-value">
          <SelectBox
            items={categories.data}
            placeholder="Chọn danh mục"
            displayExpr="name"
            searchEnabled={true}
            valueExpr="id"
            onValueChanged={(args) => {
              setCategoryId(args.value);
            }}
          />
        </div>
      </div>

      <FileUploader
        allowedFileExtensions={[".xls", ".xlsx"]}
        onValueChanged={changeValue}
        uploadMode="useForm"
      ></FileUploader>

      <div>{`Import ${importData.length} sản phẩm`}</div>

      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={{
          icon: "upload",
          text: "Import",
          onClick: importProduct,
        }}
      />
    </Popup>
  );
}

ProductImportExcel.propTypes = {
  token: PropsType.string,
  visible: PropsType.bool,
  hidePopup: PropsType.func,
  insertImage: PropsType.func,
  refreshList: PropsType.func,
};

export default ProductImportExcel;
