import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { BASE_URL, VERSION } from "../../utils/constants";
import { Button } from "devextreme-react/button";
import Box, { Item } from "devextreme-react/box";
import DropDownBox from "devextreme-react/drop-down-box";
import DataGrid, {
  Selection,
  Paging,
  FilterRow,
  Column,
  Pager,
  Lookup,
} from "devextreme-react/data-grid";
import useCustomStore from "../../hook/useCustomStore";
import ScrollView from "devextreme-react/scroll-view";
import { formatMessage } from "devextreme/localization";
import "./product-selectbox.css";

const URL = `${BASE_URL}${VERSION}product`;
const productsData = useCustomStore(URL, {
  key: "id",
  filter: {},
  include: ["meta"]
});

const getMetaValue = (key) => (rowData) => {
  const data = rowData.meta?.filter(meta => meta.key === key);

  return data && data.length ? data[0].value : ''
}

const ProductSelectBox = ({ onValueChanged, filter, token, groupType }) => {
  const dataGridRef = useRef(null);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);

  productsData.setOptions({
    token: token,
    filter: filter,

  });


  const handleValueChange = (e) => {
    setSelectedProductIds(
      (e.selectedRowKeys.length && e.selectedRowKeys) || []
    );
    setSelectedProduct(e.selectedRowsData);
  };

  const saveClick = () => {
    onValueChanged(selectedProduct);
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        icon: "clear",
        text: "Xóa đã chọn",
        onClick: () => dataGridRef.current.instance.clearSelection(),
      },
      disabled: !selectedProductIds.length,
    });
  };


  const dataGridRender = () => {
    return (
      <ScrollView width="100%" height="100%">
        <DataGrid
          ref={dataGridRef}
          height={400}
          dataSource={productsData.store}
          remoteOperations={true}
          hoverStateEnabled={true}
          selectedRowKeys={selectedProductIds}
          onSelectionChanged={handleValueChange}
          onToolbarPreparing={onToolbarPreparing}
          scrolling={{
            mode: 'virtual'
          }}
        >
          <Selection mode="multiple" selectAllMode="page" />
          <Paging enabled={true} defaultPageSize={5} />
          <Pager
            visible={true}

            allowedPageSizes={[5, 10, 20, 1000]}
            displayMode={"full"}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
          <FilterRow visible={true} />

          <Column dataField="sku" caption={formatMessage("sku")} />
          <Column dataField="name" caption={formatMessage("name")} />
          {
            groupType !== 1 ? null : (
              <Column
                calculateFilterExpression={(filterValue, selectedFilterOperator) => {
                  return [['meta.some.key', '=', 'gioi-tinh'], "and", ['meta.some.value', selectedFilterOperator, filterValue]]
                }}
                name="gender"
                calculateCellValue={getMetaValue('gioi-tinh')} caption={formatMessage("gender")} >
                <Lookup dataSource={["Nam", "Nữ", "Unisex"]} />
              </Column>
            )
          }
          {
            groupType !== 1 ? null : (
              <Column
                calculateFilterExpression={(filterValue, selectedFilterOperator) => {
                  return [['meta.some.key', '=', 'loai-day'], "and", ['meta.some.value', selectedFilterOperator, filterValue]]
                }}
                name="strapType"
                calculateCellValue={getMetaValue('loai-day')} caption={formatMessage("strapType")}
              >
                <Lookup dataSource={[
                  "Dây kim loại",
                  "Dây da",
                  "Dây dù",
                  "Dây cao su",
                  "Khác"
                ]} />
              </Column>
            )
          }
          {
            groupType !== 1 ? null : (
              <Column
                calculateFilterExpression={(filterValue, selectedFilterOperator) => {
                  return [['meta.some.key', '=', 'loai-may'], "and", ['meta.some.value', selectedFilterOperator, filterValue]]
                }}
                name="machineType"
                calculateCellValue={getMetaValue('loai-may')} caption={formatMessage("machineType")}
              >
                <Lookup dataSource={[
                  "Máy Cơ (Automatic - Handwinding)",
                  "Máy Pin (Quartz)",
                  "Máy pin năng lượng mặt trời (Solar - Eco-drive)",
                  "Máy Kinetic"
                ]} />
              </Column>
            )
          }
          <Column
            dataField="price"
            dataType="number"
            format={{ style: "currency", currency: "VND" }}
            caption={formatMessage("price")}
          />
        </DataGrid>

        <div className="selected-data">
          <span className="caption">Selected:</span>{selectedProductIds.length} items
        </div>
      </ScrollView >
    );
  };

  const syncDataGridSelection = (e) => {
    setSelectedProductIds(e.value || []);
  };

  return (
    <div style={{ marginTop: "15px" }}>
      <Box direction="row" crossAlign="stretch" width="100%">
        <Item ratio={1}>
          <DropDownBox
            value={selectedProductIds}
            valueExpr="id"
            deferRendering={false}
            displayExpr="name"
            placeholder="Thêm sản phẩm vào nhóm"
            dataSource={selectedProduct}
            onValueChanged={syncDataGridSelection}
            contentRender={dataGridRender}
          />
        </Item>

        <Item ratio={0} baseSize={20}></Item>

        <Item ratio={0} baseSize={50}>
          <Button
            icon="add"
            disabled={!selectedProductIds}
            onClick={saveClick}
          />
        </Item>
      </Box>
    </div>
  );
};

ProductSelectBox.propTypes = {
  token: PropTypes.string,
  onValueChanged: PropTypes.func,
  filter: PropTypes.object,
  groupType: PropTypes.number
};

export default ProductSelectBox;
