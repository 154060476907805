import { LOGIN_SUCCESS } from "../actions/authActions";
import {
  USER_CHECK,
  USER_UPDATE_FAILURE,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
} from "../actions/userActions";
import notify from "devextreme/ui/notify";

const INITIAL_STATE = {
  data: {
    id: 1,
    username: "admin",
    email: "admin@doseco.vn",
    screenName: "Doseco Admin",
    fullName: "Doseco Admin",
    avatar: null,
    status: 1,
  },
  error: null,
  loading: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      localStorage.setItem("user", JSON.stringify(action.payload.user));

      return {
        ...state,
        data: action.payload.user,
      };
    }

    case USER_CHECK: {
      const user = localStorage.getItem("user");

      return {
        ...state,
        data: user ? JSON.parse(user) : {},
      };
    }

    case USER_UPDATE_REQUEST: {
      return {
        ...state,
        error: null,
        loading: true,
      };
    }

    case USER_UPDATE_SUCCESS: {
      localStorage.setItem("user", JSON.stringify(action.payload));
      notify(
        {
          message: "Cập nhật thông tin thành công!",
          position: {
            my: "center top",
            at: "center top",
          },
        },
        "success",
        3000
      );

      return {
        ...state,
        data: action.payload,
      };
    }

    case USER_UPDATE_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;
