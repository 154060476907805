import React, { useState } from "react";
import { Popup } from "devextreme-react/popup";
import PropsType from "prop-types";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react";
import { BASE_URL } from "../../utils/constants";
import tranformer from "../../utils/transformer";
import notify from "devextreme/ui/notify";

export default function ChangePassPopup({ visible, hidePopup, userId, token }) {
  const [newPassword, setNewPassword] = useState("");
  const onChangePass = () => {
    tranformer.fetchUpdate(`${BASE_URL}user/${userId}/changePassword`, {
      token,
      body: {
        newPassword,
      },
      callback: () => { },
    });

    notify(
      {
        message: "Đổi mật khẩu thành công",
        position: {
          my: "center top",
          at: "center top",
        },
      },
      "success",
      3000
    );
    hidePopup();
  };

  return (
    <Popup
      visible={visible}
      onHiding={hidePopup}
      dragEnabled={false}
      hideOnOutsideClick={false}
      showCloseButton={true}
      showTitle={true}
      title="Đổi mật khẩu"
      container=".dx-viewport"
      width={400}
      height={200}
    >
      <div className="dx-field">
        <div className="dx-field-label">Nhập mật khẩu mới</div>
        <div className="dx-field-value">
          <TextBox
            mode="password"
            onValueChanged={(e) => setNewPassword(e.value)}
          />
        </div>
      </div>

      <br />
      <Button
        width={120}
        text="Lưu"
        type="default"
        stylingMode="contained"
        onClick={onChangePass}
      />
    </Popup>
  );
}

ChangePassPopup.propTypes = {
  visible: PropsType.bool,
  hidePopup: PropsType.func,
  userId: PropsType.string,
  token: PropsType.string,
};
