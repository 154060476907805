const GroupVM = (data) => {
  data.brands = data.brands && Array.isArray(data.brands) ? data.brands.map(item => (item.id)) : undefined
  const returnData = Object.assign({}, data);

  return returnData;
};

const GroupMV = (data) => {
  return {
    name: data.name,
    type: data.type,
    key: data.key,
    description: data.description,
    homepage: data.homepage,
    thumbnail: data.thumbnail ? { id: data.thumbnail.id } : undefined,
    parent: data.parentId ? { id: data.parentId } : undefined,
    reference: data.reference,
    referenceGroup: data.referenceGroup,
    showDescription: data.showDescription,
    brands: data.brands && Array.isArray(data.brands) ? data.brands.map(id => ({ id })) : undefined
  };
};

export { GroupVM, GroupMV };
