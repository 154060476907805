import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getNewToken } from "../actions/authActions";
import PropsType from "prop-types";
import { useHistory } from "react-router-dom";

const ExpiredTokenContainer = (props) => {
  const history = useHistory();

  useEffect(() => {
    console.log("refresh token");
    if (props.refresh_token) {
      props.getNewToken({
        grant_type: "refresh_token",
        refresh_token: props.refresh_token,
      });
    }
  }, []);

  useEffect(() => {
    if (props.isAuthenticated) {
      history.goBack();
    }
  }, [props.isAuthenticated]);

  return (
    <div>
      Your token has been expired! Please go to login page! <br />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.data.isAuthenticated,
    refresh_token: state.auth.data.refresh_token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNewToken: (payload) => {
      return dispatch(getNewToken(payload));
    },
  };
};

ExpiredTokenContainer.propTypes = {
  clearToken: PropsType.func,
  getNewToken: PropsType.func,
  isAuthenticated: PropsType.bool,
  refresh_token: PropsType.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpiredTokenContainer);
