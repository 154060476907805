import React, { useEffect, useState } from "react";
import Form, {
  ButtonItem,
  SimpleItem,
  GroupItem,
  Label,
  RequiredRule,
} from "devextreme-react/form";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { useHistory, useParams } from "react-router-dom";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../data/dictionary";
import {
  BASE_URL,
  HTML_ITEMS,
  PRODUCT_TYPE,
  VERSION,
} from "../utils/constants";
import { connect } from "react-redux";
import PropsType from "prop-types";
import notify from "devextreme/ui/notify";
import KeyMultiValue from "../components/KeyMultiValue";
import { CategoryMV, CategoryVM } from "../model/Category";
import ImageUploader from "../components/ImageUploader";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import useFetch from "../hook/useFetch";

loadMessages(dictionary);
locale("vi");
var qs = require("qs");

const CategoryDetailContainer = ({ token }) => {
  const history = useHistory();
  const params = useParams();
  const [category, setCategory] = useState({ meta: {} });

  const categories = useFetch(`${BASE_URL}category`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  useEffect(() => {
    if (!params.id) {
      return;
    }
    const include = ["parent", "thumbnail"];
    fetch(
      `${BASE_URL}${VERSION}category/${params.id}?${qs.stringify({ include })}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          setCategory(CategoryVM(json.data));
          return;
        }
        throw json.Message;
      })
    );
  }, [token, params.id, history]);

  const backButtonOptions = {
    type: "back",
    onClick: () => {
      history.goBack();
    },
  };

  const saveButtonOptions = {
    text: formatMessage("save"),
    icon: "save",
    type: "default",
    stylingMode: "contained",
    useSubmitBehavior: true,
  };

  const updateCategory = () => {
    return fetch(`${BASE_URL}${VERSION}category/${category.id}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(CategoryMV(category)),
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          history.goBack();
          notify(
            {
              message: json.message,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
          return;
        }
        throw json.Message;
      })
    );
  };

  const addCategory = () => {
    return fetch(`${BASE_URL}${VERSION}category`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(CategoryMV(category)),
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          history.goBack();
          notify(
            {
              message: json.message,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
          return;
        }
        throw json.Message;
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (params.id) {
      updateCategory();
    } else {
      addCategory();
    }
  };

  const addMetaButtonOptions = {
    icon: "add",
    text: "Add Meta",
    onClick: () => {
      const metaIndex = Object.keys(category.meta).length;
      setCategory((prevState) => ({
        ...prevState,
        meta: {
          ...prevState.meta,
          [`meta${metaIndex}`]: {
            title: "Tiêu đề",
            value: ["giá trị 1"],
            type: "array",
            show: true,
            unit: "",
          },
        },
      }));
    },
  };

  const onKeyValueChange = (data) => {
    setCategory((prevState) => ({
      ...prevState,
      meta: {
        ...prevState.meta,
        [data.metaKey]: {
          title: data.title,
          value: data.value,
          unit: data.unit,
          type: data.type,
          show: data.show,
        },
      },
    }));
  };

  const typesSelectOptions = {
    items: PRODUCT_TYPE,
    displayExpr: "name",
    valueExpr: "value",
  };

  const parentIdSelectOptions = {
    items: categories.data,
    displayExpr: "name",
  };

  const userManualHtmlOptions = {
    toolbar: {
      multiline: true,
      items: HTML_ITEMS,
    },
    height: 500,
  };

  const insuranceHtmlOptions = {
    toolbar: {
      multiline: true,
      items: HTML_ITEMS,
    },
    height: 500,
  };

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="purple" contentPosition="left">
                <h2 className="text-white text-2xl">
                  {params.id ? "Chi tiết danh mục" : "Thêm mới danh mục"}
                </h2>
              </CardHeader>
              <CardBody>
                <Toolbar>
                  <Item
                    location="before"
                    widget="dxButton"
                    options={backButtonOptions}
                  />
                  <Item
                    location="center"
                    text={`${formatMessage("info")} ${formatMessage(
                      "of"
                    )} ${formatMessage("category")}`}
                  />
                </Toolbar>

                <form action="your-action" onSubmit={handleSubmit}>
                  <Form
                    formData={category}
                    readOnly={false}
                    showColonAfterLabel={true}
                    colCount={1}
                    showValidationSummary={true}
                  >
                    <GroupItem caption="Thông tin" colCount={2}>
                      <GroupItem>
                        <SimpleItem dataField={"name"} editorType="dxTextBox">
                          <Label text={formatMessage("name")} />
                          <RequiredRule />
                        </SimpleItem>
                        <SimpleItem
                          dataField={"key"}
                          editorOptions={{ readOnly: !!params.id }}
                        >
                          <Label text={formatMessage("key")} />
                          <RequiredRule />
                        </SimpleItem>
                        <SimpleItem
                          dataField={"type"}
                          editorType="dxSelectBox"
                          editorOptions={typesSelectOptions}
                        >
                          <Label text={formatMessage("type")} />
                        </SimpleItem>
                        <SimpleItem
                          dataField={"parentId"}
                          editorType="dxSelectBox"
                          editorOptions={parentIdSelectOptions}
                        >
                          <Label text={formatMessage("parent")} />
                        </SimpleItem>
                      </GroupItem>

                      <GroupItem>
                        <SimpleItem>
                          <Label text={formatMessage("thumbnail")} />
                          <ImageUploader
                            defaultValue={category.thumbnail}
                            onImgUrlChange={(value) =>
                              setCategory({ ...category, thumbnail: value })
                            }
                            token={token}
                          />
                        </SimpleItem>
                      </GroupItem>

                      <SimpleItem
                        dataField={"insurance"}
                        editorType="dxHtmlEditor"
                        editorOptions={insuranceHtmlOptions}
                      >
                        <Label text={formatMessage("insurance")} />
                      </SimpleItem>

                      <SimpleItem
                        dataField={"userManual"}
                        editorType="dxHtmlEditor"
                        editorOptions={userManualHtmlOptions}
                      >
                        <Label text={formatMessage("userManual")} />
                      </SimpleItem>
                    </GroupItem>

                    <GroupItem caption="Meta" visible={true}>
                      {Object.keys(category.meta).map((element, index) => (
                        <SimpleItem key={element}>
                          <KeyMultiValue
                            onValueChange={onKeyValueChange}
                            data={category.meta[element]}
                            metaKey={element}
                            index={index}
                          />
                        </SimpleItem>
                      ))}
                      <ButtonItem
                        horizontalAlignment="left"
                        buttonOptions={addMetaButtonOptions}
                      ></ButtonItem>
                    </GroupItem>

                    <ButtonItem
                      horizontalAlignment="center"
                      buttonOptions={saveButtonOptions}
                    />
                  </Form>
                </form>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.data.token,
    lang: state.setting.language.currentLang,
  };
};

CategoryDetailContainer.propTypes = {
  token: PropsType.string,
};

export default connect(mapStateToProps, null)(CategoryDetailContainer);
