import React, { useState, useRef } from "react";
import { DataGrid, Column, Editing } from "devextreme-react/data-grid";
import PropsType from "prop-types";
import { BASE_URL, FILE_URL, VERSION } from "../utils/constants";
import CustomStore from "devextreme/data/custom_store";
import ImageUploader from "./ImageUploader";
import notify from "devextreme/ui/notify";
import Gallery from "devextreme-react/gallery";
import Box, { Item } from "devextreme-react/box";
import Card from "@material-tailwind/react/Card";
import CardBody from "@material-tailwind/react/CardBody";
import ProductImageUploader from "./ProductImageUploader";
import { confirm } from "devextreme/ui/dialog";
import tranformer from "../utils/transformer";

var qs = require("qs");

const ProductImageList = ({ images, token, productId }) => {
  const [showUploadImg, setShowUploadImg] = useState(false);
  const dataGridRef = useRef(null);

  const insertRequest = (url, method, data) => {
    return fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: data.path.id,
        position: data.position,
      }),
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          notify(
            {
              message: json.result,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
          return json.data.images.find((x) => x.id === data.id);
        }
        throw json.message;
      })
    );
  };

  const deleteRequest = (url, method, key) => {
    return fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        imageIds: [key],
      }),
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          notify(
            {
              message: json.result,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
          return;
        }
        throw json.message;
      })
    );
  };

  const loadRequest = () => {
    const include = ["images"];
    return fetch(
      `${BASE_URL}${VERSION}product/${productId}?${qs.stringify({
        include,
      })}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          return json.data.images;
        }
        throw json.Message;
      })
    );
  };

  const updateRequest = (url, method, key, value) => {
    return fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        images: [{ id: key, ...value }],
      }),
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          notify(
            {
              message: "Cập nhật thành công!",
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
          return;
        }
        throw json.message;
      })
    );
  };

  const imgsList = new CustomStore({
    key: "id",
    load: () => loadRequest(),
    insert: (values) =>
      insertRequest(`${BASE_URL}product/${productId}/image`, "POST", values),
    remove: (key) =>
      deleteRequest(`${BASE_URL}product/${productId}/image`, "DELETE", key),
    update: (key, value) =>
      updateRequest(`${BASE_URL}product/${productId}/image`, "PUT", key, value),
  });

  const imgCellRender = (data) => {
    return <img style={{ height: 30 }} src={`${FILE_URL}${data.value}`} />;
  };

  const imgEditCellRender = (cell) => {
    return (
      <ImageUploader
        defaultValue={cell.data}
        onImgUrlChange={(value) => cell.setValue(value)}
        token={token}
      />
    );
  };

  const deleteAllImages = () => {
    let result = confirm(
      "<i>Bạn chắc chắn muốn xóa tất cả ảnh?</i>",
      "Xóa sản phẩm"
    );
    result.then((dialogResult) => {
      if (dialogResult) {
        tranformer.fetchUpdate(`${BASE_URL}${VERSION}product/${productId}`, {
          token,
          body: { images: [] },
          callback: () => {
            refreshList();
          },
        });
      }
    });
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        locateInMenu: "auto",
        options: {
          icon: "trash",
          onClick: deleteAllImages,
        },
      },
      {
        location: "after",
        widget: "dxButton",
        locateInMenu: "auto",
        options: {
          icon: "plus",
          onClick: () => setShowUploadImg(true),
        },
      }
    );
  };

  const refreshList = () => {
    dataGridRef.current.instance.refresh();
    setShowUploadImg(false);
  };

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24 mb-12">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardBody>
                <Box direction="row">
                  <Item ratio={1}>
                    <Gallery
                      id="gallery"
                      dataSource={images.map(
                        (item) => `${FILE_URL}${item.path}`
                      )}
                      slideshowDelay={2000}
                      height={500}
                      loop={true}
                      showNavButtons={true}
                      showIndicator={true}
                    />
                  </Item>
                  <Item ratio={0} baseSize={50}></Item>
                  <Item ratio={2}>
                    {showUploadImg && (
                      <ProductImageUploader
                        token={token}
                        productId={productId}
                        refreshList={refreshList}
                      />
                    )}

                    <DataGrid
                      id="grid"
                      showBorders={true}
                      dataSource={imgsList}
                      ref={dataGridRef}
                      selection={{ mode: "single" }}
                      repaintChangesOnly={true}
                      onToolbarPreparing={onToolbarPreparing}
                    >
                      <Editing
                        refreshMode={"reshape"}
                        mode="cell"
                        useIcons={true}
                        allowDeleting={true}
                        allowUpdating={true}
                        // allowAdding={true}
                      />

                      <Column
                        dataField="id"
                        editorOptions={{ readOnly: true }}
                      />
                      <Column dataField="position" dataType="number" />
                      <Column
                        dataField="path"
                        editCellRender={imgEditCellRender}
                        cellRender={imgCellRender}
                      />
                    </DataGrid>
                  </Item>
                </Box>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

ProductImageList.propTypes = {
  token: PropsType.string,
  images: PropsType.array,
  productId: PropsType.number,
};

export default ProductImageList;
