import React, { useRef } from "react";
import { connect } from "react-redux";
import PropsType from "prop-types";
import {
  DataGrid,
  Column,
  Pager,
  Paging,
  Lookup,
  FilterPanel,
  FilterRow,
  MasterDetail,
  Editing,
} from "devextreme-react/data-grid";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../data/dictionary";
import { BASE_URL, ORDER_STATUS, VERSION } from "../utils/constants";
import { useHistory } from "react-router-dom";
import useFetch from "../hook/useFetch";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
// import Button from "@material-tailwind/react/Button";
import useCustomStore from "../hook/useCustomStore";
import { PaymentMethodVM } from "../model/Payment";
import OrderProductsList from "../components/order/OrderProductsList";

const allowedPageSizes = [10, 20, 50];
loadMessages(dictionary);
locale("vi");

const URL = `${BASE_URL}${VERSION}order`;
const ordersData = useCustomStore(URL, {
  key: "id",
});

const OrderContainer = (props) => {
  const dataGridRef = useRef(null);
  const history = useHistory();

  const payment = useFetch(`${BASE_URL}${VERSION}paymentMethod`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${props.token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  ordersData.setOptions({
    token: props.token,
    filter: {},
    include: ["customer", "payment", "items"],
    history: history,
  });

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      locateInMenu: "auto",
      options: {
        icon: "refresh",
        onClick: () => dataGridRef.current.instance.refresh(),
      },
    });
  };

  const onRowDblClick = (e) => {
    history.push(`/order/${e.key}`);
  };

  const onCellPrepared = (e) => {
    if (e.rowType === "data" && e.column.dataField === "status") {
      const { status } = e.data;
      if (status === 1) {
        //Order
        e.cellElement.style.backgroundColor = "#ffa500";
      } else if (status === 2) {
        //In Processing
        e.cellElement.style.backgroundColor = "#1BB3F5";
      } else if (status === 3) {
        //Shipping
        e.cellElement.style.backgroundColor = "#add8e6";
      } else if (status === 4) {
        //Complete
        e.cellElement.style.backgroundColor = "#90ee90";
      } else if (status === 5) {
        //Cancel
        e.cellElement.style.backgroundColor = "#d3d3d3";
      }
    }
  };

  // const dateRendering = (e) => {
  //   return (
  //     <div>
  //       {new Date(e.value).toLocaleString("vi-VN", {
  //         timeZone: "Asia/Ho_Chi_Minh",
  //       })}
  //     </div>
  //   );
  // };

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="purple" contentPosition="none">
                <div className="w-full flex items-center justify-between">
                  <h2 className="text-white text-2xl">Danh sách đơn hàng</h2>
                  {/* <Button
                    color="transparent"
                    buttonType="link"
                    size="lg"
                    style={{ padding: 0 }}
                    onClick={() => history.push(`/order/add`)}
                  >
                    Thêm mới
                  </Button> */}
                </div>
              </CardHeader>
              <CardBody>
                <DataGrid
                  id="id"
                  ref={dataGridRef}
                  showBorders={true}
                  dataSource={ordersData.store}
                  selection={{ mode: "single" }}
                  columnResizingMode={"widget"}
                  repaintChangesOnly={true}
                  allowColumnResizing={true}
                  remoteOperations={true}
                  onRowDblClick={onRowDblClick}
                  onToolbarPreparing={onToolbarPreparing}
                  onCellPrepared={onCellPrepared}
                >
                  <Editing mode="cell" allowUpdating={true} />
                  <FilterRow visible={true} />
                  <FilterPanel visible={true} />

                  <Paging defaultPageSize={20} />
                  <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={"full"}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />

                  <Column
                    dataField="code"
                    caption={formatMessage("code")}
                    allowEditing={false}
                  />
                  <Column
                    dataField="createdAt"
                    caption={formatMessage("createdAt")}
                    dataType="datetime"
                    // cellRender={dateRendering}
                    sortOrder={"desc"}
                    allowEditing={false}
                  />

                  <Column caption="Khách hàng" alignment="center">
                    <Column
                      dataField="customer.name"
                      caption={formatMessage("name")}
                      allowEditing={false}
                    />
                    <Column
                      dataField="customer.phone"
                      caption={formatMessage("phone")}
                      allowEditing={false}
                    />
                    {/* <Column
                      dataField="address"
                      caption={formatMessage("address")}
                    /> */}
                  </Column>

                  <Column dataField="note" caption={formatMessage("note")} />

                  <Column
                    dataField="payment.methodId"
                    caption={formatMessage("paymentMethod")}
                    allowEditing={false}
                  >
                    <Lookup
                      dataSource={payment.data.map((x) => PaymentMethodVM(x))}
                      valueExpr="id"
                      displayExpr="name"
                    />
                  </Column>
                  <Column dataField="status" caption={formatMessage("status")}>
                    <Lookup
                      dataSource={ORDER_STATUS}
                      valueExpr="value"
                      displayExpr="name"
                    />
                  </Column>

                  <Column caption="Thanh toán" alignment="center">
                    <Column
                      dataField="grossTotal"
                      caption={formatMessage("grossTotal")}
                      dataType="number"
                      format={{ style: "currency", currency: "VND" }}
                      allowEditing={false}
                    />
                    {/* <Column
                      dataField="shippingTotal"
                      caption={formatMessage("shippingTotal")}
                      dataType="number"
                      format={{ style: "currency", currency: "VND" }}
                    /> */}
                    <Column
                      dataField="netTotal"
                      caption={formatMessage("netTotal")}
                      dataType="number"
                      format={{ style: "currency", currency: "VND" }}
                      allowEditing={false}
                    />
                  </Column>

                  <MasterDetail
                    enabled={true}
                    component={({ data }) => (
                      <OrderProductsList token={props.token} data={data} />
                    )}
                  />
                </DataGrid>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.data.token,
    lang: state.setting.language.currentLang,
  };
};

OrderContainer.propTypes = {
  token: PropsType.string,
  lang: PropsType.string,
};

export default connect(mapStateToProps, null)(OrderContainer);
