import {
    LANGUAGE_CHANGE, LANGUAGE_INIT
} from '../actions/settingActions';

const INITIAL_STATE = {
    language: {
        currentLang: 'vi'
    }
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LANGUAGE_CHANGE: {
            localStorage.setItem('language', action.payload.lang);

            return {
                ...state,
                language: {
                    currentLang: action.payload.lang
                }
            };
        }

        case LANGUAGE_INIT: {
            const language = localStorage.getItem('language');

            return {
                ...state,
                language: {
                    currentLang: language || 'vi'
                }
            };
        }

        default:
            return state;
    }
};

export default reducer;
