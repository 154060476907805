import React from "react";
import {
  Chart,
  Series,
  CommonSeriesSettings,
  Label,
  Format,
} from "devextreme-react/chart";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import PropsType from "prop-types";

const SeriesChart = ({ data }) => {
  const viewData = data.map((x) => {
    return { ...x, name: `${x.month}/${x.year}` };
  });

  return (
    <Card>
      <CardHeader color="purple" contentPosition="left">
        <div className="w-full flex items-center justify-between">
          <h2 className="text-white text-2xl">
            Số lượng đơn hàng 6 tháng gần nhất
          </h2>
        </div>
      </CardHeader>
      <CardBody>
        <Chart id="chart" dataSource={viewData}>
          <CommonSeriesSettings
            argumentField="state"
            type="bar"
            hoverMode="allArgumentPoints"
            selectionMode="allArgumentPoints"
          >
            <Label visible={true}>
              <Format type="fixedPoint" precision={0} />
            </Label>
          </CommonSeriesSettings>
          <Series
            valueField="count"
            argumentField="name"
            type="bar"
            name="Đơn hàng"
            color="#4169E1"
          />
        </Chart>
      </CardBody>
    </Card>
  );
};

SeriesChart.propTypes = {
  data: PropsType.array,
};

export default SeriesChart;
