import DataGrid, {
  Column,
  Button,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import React from "react";
import PropTypes from "prop-types";
import { formatMessage } from "devextreme/localization";
import { confirm } from "devextreme/ui/dialog";

function ProductList({ removeAction, data }) {
  const removeProduct = (e) => {
    const product = e.row.data;

    let result = confirm(
      "<i>Bạn chắc chắn muốn xóa sản phẩm khỏi nhóm?</i>",
      "Xóa sản phẩm"
    );
    result.then((dialogResult) => {
      if (dialogResult) {
        removeAction(product);
      }
    });
  };

  return (
    <div className="mt-4">
      <DataGrid dataSource={data} keyExpr="id" showBorders={true}>
        <Paging defaultPageSize={10} />
        <Pager
          visible={true}
          allowedPageSizes={[5, 10, 20, "all"]}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />

        <Column dataField="sku" caption={formatMessage("sku")} />
        <Column dataField="name" caption={formatMessage("name")} />
        <Column
          dataField="price"
          dataType="number"
          format={{ style: "currency", currency: "VND" }}
          caption={formatMessage("price")}
        />
        <Column type="buttons">
          <Button icon="trash" onClick={removeProduct} />
        </Column>
      </DataGrid>
    </div>
  );
}

ProductList.propTypes = {
  removeAction: PropTypes.func,
  data: PropTypes.array,
};

export default ProductList;
