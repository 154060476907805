import DataGrid, {
  Column,
  Button,
  FilterRow,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import React, { useEffect, useState } from "react";
import PropsType from "prop-types";
import ProductSelectBox from "../product/ProductSelectBox";
import tranformer from "../../utils/transformer";
import { BASE_URL, FRIENDLY_GROUP_TYPE, VERSION } from "../../utils/constants";
import { confirm } from "devextreme/ui/dialog";
import { formatMessage } from "devextreme/localization";
import { CheckBox } from "devextreme-react/check-box";

var qs = require("qs");

function GroupProducts({
  data,
  token,
  groupId,
  refreshList,
  groupKey,
  childGroupId,
  type,
}) {
  const [homeProductIds, setHomeProductIds] = useState([]);
  const connectKey =
    type !== FRIENDLY_GROUP_TYPE ? "products" : "friendlyProduct";

  useEffect(() => {
    if (groupKey === "san-pham-giam-gia") {
      refreshChildGroupProduct();
    }
  }, [groupKey]);

  const refreshChildGroupProduct = () => {
    const include = ["products"];

    fetch(
      `${BASE_URL}${VERSION}group/${childGroupId}?${qs.stringify({
        include,
      })}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          setHomeProductIds(json.data.products.map((x) => x.id));
          return;
        }
        throw json.Message;
      })
    );
  };

  const addProductToGroup = (products) => {
    tranformer.fetchUpdate(
      `${BASE_URL}${VERSION}group/${groupId}/${connectKey}/connectMany`,
      {
        token,
        body: products.map((x) => {
          return { id: x.id };
        }),
        callback: () => {
          refreshList();
        },
      }
    );
  };

  const removeProductFromGroup = (e) => {
    const product = e.row.data;

    let result = confirm(
      "<i>Bạn chắc chắn muốn xóa sản phẩm khỏi nhóm?</i>",
      "Xóa sản phẩm"
    );
    result.then((dialogResult) => {
      if (dialogResult) {
        tranformer.fetchUpdate(
          `${BASE_URL}${VERSION}group/${groupId}/${connectKey}/disconnectMany`,
          {
            token,
            body: [{ id: product.id }],
            callback: () => {
              refreshList();
            },
          }
        );
      }
    });
  };

  const checkBoxRender = (e) => {
    return (
      <div style={{ textAlign: "center" }}>
        <CheckBox
          defaultValue={homeProductIds.includes(e.key)}
          onValueChanged={(args) => updateProductGroup(e.key, args.value)}
        />
      </div>
    );
  };

  const updateProductGroup = (productId, value) => {
    if (value) {
      tranformer.fetchUpdate(
        `${BASE_URL}${VERSION}group/${childGroupId}/${connectKey}/connect/${productId}`,
        {
          token,
          body: {},
          callback: () => {
            refreshChildGroupProduct();
          },
        }
      );
    } else {
      tranformer.fetchUpdate(
        `${BASE_URL}${VERSION}group/${childGroupId}/${connectKey}/disconnect/${productId}`,
        {
          token,
          body: {},
          callback: () => {
            refreshChildGroupProduct();
          },
        }
      );
    }
  };

  return (
    <>
      {groupKey !== "san-pham-hien-thi-o-trang-chu" ? (
        <ProductSelectBox
          token={token}
          onValueChanged={addProductToGroup}
          groupType={type}
          filter={
            type !== FRIENDLY_GROUP_TYPE
              ? { groups: { none: { id: groupId } }, type }
              : {
                OR: [
                  { friendlyGroupId: { not: groupId } },
                  { friendlyGroupId: { equals: null } },
                ],
              }
          }
        />
      ) : (
        "Vui lòng chỉnh sửa sản phẩm ở trong nhóm sản phẩm giảm giá"
      )}

      <div className="mt-4">
        <DataGrid
          dataSource={data}
          keyExpr="id"
          showBorders={true}
          selection={{ mode: "single" }}
        >
          <Paging defaultPageSize={10} />
          <Pager
            visible={true}
            allowedPageSizes={[5, 10, 20, "all"]}
            displayMode={"full"}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />

          <FilterRow visible={true} />
          <Column dataField="sku" caption={formatMessage("sku")} />
          <Column dataField="name" caption={formatMessage("name")} />
          <Column
            dataField="price"
            dataType="number"
            format={{ style: "currency", currency: "VND" }}
            caption={formatMessage("price")}
          />
          <Column
            caption={formatMessage("isHomePage")}
            width={120}
            dataType="boolean"
            visible={groupKey === "san-pham-giam-gia"}
            cellRender={checkBoxRender}
          />
          <Column
            type="buttons"
            visible={groupKey !== "san-pham-hien-thi-o-trang-chu"}
          >
            <Button icon="trash" onClick={removeProductFromGroup} />
          </Column>
        </DataGrid>
      </div>
    </>
  );
}

GroupProducts.propTypes = {
  data: PropsType.array,
  token: PropsType.string,
  groupId: PropsType.number,
  refreshList: PropsType.func,
  groupKey: PropsType.string,
  childGroupId: PropsType.string,
  type: PropsType.number,
};

export default GroupProducts;
