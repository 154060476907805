import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import PropsType from "prop-types";
import {
  DataGrid,
  Column,
  Pager,
  Paging,
  FilterRow,
  FilterPanel,
  Lookup,
  Editing,
  Button as GridButton,
} from "devextreme-react/data-grid";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../data/dictionary";
import {
  BASE_URL,
  FILE_URL,
  GUIDE_GROUP_TYPE,
  POST_FILTER,
  POST_GROUP_TYPE,
  POST_STATUS,
  VERSION,
} from "../utils/constants";
import { Link, useHistory } from "react-router-dom";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Button from "@material-tailwind/react/Button";
import useCustomStore from "../hook/useCustomStore";

const allowedPageSizes = [10, 20, "all"];
loadMessages(dictionary);
locale("vi");

const URL = `${BASE_URL}${VERSION}post`;
const postsData = useCustomStore(URL, {
  key: "id",
});

const PostContainer = (props) => {
  const history = useHistory();
  const dataGridRef = useRef(null);
  const [filterStatus, setFilterStatus] = useState();

  postsData.setOptions({
    token: props.token,
    filter: {
      group: { id: { in: [POST_GROUP_TYPE, GUIDE_GROUP_TYPE] } },
      status: filterStatus === null ? undefined : filterStatus
    },
    include: ["thumbnail", "author", "group"],
  });

  const selectBoxGroup = {
    items: POST_STATUS,
    displayExpr: "name",
    valueExpr: "value",
    value: filterStatus,
    width: 250,
    placeholder: "Trạng thái",
    showClearButton: true,
    onValueChanged: (args) => {
      const treeList = dataGridRef.current.instance;

      if (!args.value) {
        treeList.clearFilter();
      } else {
        if (Array.isArray(args.value)) {
          let _filter = [];
          args.value.forEach((element) => {
            _filter.push(["status", "=", element]);
            _filter.push("or");
          });
          _filter.pop();
          treeList.filter(_filter);
        } else {
          treeList.filter(["status", "=", args.value]);
        }
      }

      setFilterStatus(args.value);
    },
  };

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift(
      {
        location: "after",
        widget: "dxButton",
        locateInMenu: "auto",
        options: {
          icon: "refresh",
          onClick: () => dataGridRef.current.instance.refresh(),
        },
      },
      {
        location: "before",
        widget: "dxSelectBox",
        locateInMenu: "auto",
        options: selectBoxGroup,
      }
    );
  };

  const imgCellRender = (data) => {
    if (!data.value) {
      return <div></div>;
    }
    return <img style={{ height: 30 }} src={`${FILE_URL}${data.value.path}`} />;
  };

  const onRowDblClick = (e) => {
    history.push(`/post/${e.key}`);
  };

  const editButtonRender = (e) => {
    return (
      <Link to={`/post/${e.key}`}>
        <i
          className="dx-icon dx-icon-edit"
          // onClick={() => history.push(`/post/${e.key}`)}
          style={{ fontSize: 18, color: "#337ab7" }}
        />
      </Link>
    );
  };

  const publishButtonRender = (e) => {
    if (e.data?.status === "draft") {
      return (
        <Link to='#' onClick={() => {
          fetch(`${BASE_URL}post/${e.data.id}/status/publish`, {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${props.token}`,
              "Access-Control-Allow-Origin": "*",
            },
          }).then(() => dataGridRef.current.instance.refresh())
        }}>
          <i
            className="dx-icon dx-icon-globe"

            style={{ fontSize: 18, color: "#337ab7" }}
          />
        </Link >

      );
    }
    return undefined
  };

  const onCellPrepared = (e) => {
    if (e.rowType === "data" && e.column.dataField === "status") {
      const { status } = e.data;
      if (status === 'publish') {
        //Order
        e.cellElement.style.backgroundColor = "#90ee90";
      } else if (status === 'draft') {
        //In Processing
        e.cellElement.style.backgroundColor = "#d3d3d3";
      }
    }
  };

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="purple" contentPosition="none">
                <div className="w-full flex items-center justify-between">
                  <h2 className="text-white text-2xl">Danh sách bài viết</h2>
                  <Button
                    color="transparent"
                    buttonType="link"
                    size="lg"
                    style={{ padding: 0 }}
                    onClick={() => history.push(`/post/add`)}
                  >
                    Thêm mới
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <DataGrid
                  id="grid"
                  ref={dataGridRef}
                  showBorders={true}
                  dataSource={postsData.store}
                  remoteOperations={true}
                  repaintChangesOnly={true}
                  selection={{ mode: "single" }}
                  allowColumnResizing={true}
                  onRowDblClick={onRowDblClick}
                  onToolbarPreparing={onToolbarPreparing}
                  onCellPrepared={onCellPrepared}
                >
                  <Editing useIcons={true} allowDeleting={true} />
                  <FilterRow visible={true} applyFilter={true} />
                  <FilterPanel visible={true} />

                  <Paging defaultPageSize={20} />
                  <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={"full"}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />

                  <Column dataField="title" caption={formatMessage("title")} />
                  <Column
                    dataField="slug"
                    caption={formatMessage("slug")}
                    editorOptions={{ readOnly: true }}
                  />
                  <Column dataField="groupId" caption={formatMessage("group")}>
                    <Lookup
                      dataSource={POST_FILTER}
                      valueExpr="value"
                      displayExpr="name"
                    />
                  </Column>
                  <Column
                    dataField="author.fullName"
                    caption={formatMessage("author")}
                  />
                  <Column
                    dataField="thumbnail"
                    caption={formatMessage("thumbnail")}
                    allowSorting={false}
                    cellRender={imgCellRender}
                  />
                  <Column
                    dataField="status"
                    caption={formatMessage("status")}
                  >
                    <Lookup
                      dataSource={POST_STATUS}
                      valueExpr="value"
                      displayExpr="name"
                    />
                  </Column>
                  <Column
                    dataField="updatedAt"
                    caption={formatMessage("updatedAt")}
                    dataType="datetime"
                  />
                  <Column
                    dataField="excerpt"
                    caption={formatMessage("excerpt")}
                  />

                  <Column type="buttons">
                    <GridButton render={editButtonRender} />
                    <GridButton render={publishButtonRender} />
                    <GridButton name="delete" />
                  </Column>
                </DataGrid>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.data.token,
    lang: state.setting.language.currentLang,
  };
};

PostContainer.propTypes = {
  token: PropsType.string,
  lang: PropsType.string,
};

export default connect(mapStateToProps, null)(PostContainer);
