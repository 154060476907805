import notify from "devextreme/ui/notify";

const tranformer = {
  formatCurrency: (str) => {
    return new Intl.NumberFormat("vi-VN", {
      style: "currency",
      currency: "VND",
    }).format(str);
  },
  fetchUpdate: (url, { token, body, callback }) => {
    fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    }).then((result) =>
      result.json().then(() => {
        if (result.ok) {
          if (callback) callback();
          notify(
            {
              message: "Updated successfully",
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
        }
      })
    );
  },
  fetchCreate: (url, { token, body, callback, errCallback = () => { } }) => {
    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    })
      .then((result) =>
        result.json().then((json) => {
          console.log("json", json, result);
          if (result.ok && !json.error) {
            callback(json.data.id);
            notify(
              {
                message: "Added successfully",
                position: {
                  my: "center top",
                  at: "center top",
                },
              },
              "success",
              3000
            );
          } else {
            errCallback(json.error);
            notify(
              {
                message: json.error?.message || "Có lỗi xảy ra!",
                position: {
                  my: "center top",
                  at: "center top",
                },
              },
              "error",
              3000
            );
          }
        })
      )
      .catch((err) => {
        errCallback(err);
        console.log("err", err);
      });
  },
  fetchDelete: (url, { token, body, callback }) => {
    fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(body),
    }).then((result) =>
      result.json().then(() => {
        if (result.ok) {
          if (callback) callback();
          notify(
            {
              message: "Updated successfully",
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
        }
      })
    );
  },
  isNotEmpty: (value) => {
    return value !== undefined && value !== null && value !== "";
  },
  isNotEmptyArr: (value) => {
    return Array.isArray(value) && value.length !== 0;
  },
  convertUnsignedChars: (str) => {
    str = str.replace(/\s+/g, " ");
    str.trim();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    str = str.replace(
      /!|@|#|%|&|=|<|>|`|~|\?|\(|\)|\$|\^|\*|\+|\.|\/|\\/g,
      "-"
    );
    str = str.split(" ").join("-");
    str = str.replace(/-+/g, "-");
    return str.toLowerCase();
  },
  convertThumbnailYoutube: (link) => {
    const linkArr = link.split("/");
    const videoId = linkArr.at(-1).replace("watch?v=", "");
    if (videoId.includes('?')) {
      const idArr = videoId.split('?');

      return `https://img.youtube.com/vi/${idArr[0]}/0.jpg`;
    }
    return `https://img.youtube.com/vi/${videoId}/0.jpg`;
  },
  checkNumberInRange: (inputNumber, valueStrArr) => {
    let returnValue = null;

    valueStrArr.forEach((element) => {
      const _num = +element.match(/\d+/)[0];

      if (element.includes("<")) {
        if (inputNumber > 0 && inputNumber < _num) {
          returnValue = element;
        }
      } else if (element.includes(">")) {
        if (inputNumber > _num) {
          returnValue = element;
        }
      } else {
        const strElement = element.split("-");
        const _num1 = +strElement[0].match(/\d+/)[0];
        const _num2 = +strElement[1].match(/\d+/)[0];

        if (inputNumber > _num1 && inputNumber < _num2) {
          returnValue = element;
        }
      }
    });

    return returnValue;
  },
  getPathnameFromUrl: (url) => {
    try {
      let newUrl = new URL(url);
      return decodeURI(newUrl.pathname);
    } catch (err) {
      console.log("err", err);
      return url;
    }
  },
};

export default tranformer;
