import React, { useRef } from "react";
import { connect } from "react-redux";
import PropsType from "prop-types";
import {
  DataGrid,
  Column,
  Editing,
  RequiredRule,
  Pager,
  Paging,
} from "devextreme-react/data-grid";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../data/dictionary";
import { BASE_URL, VERSION } from "../utils/constants";
import "devextreme-react/text-area";
import useCustomStore from "../hook/useCustomStore";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import tranformer from "../utils/transformer";
import VideoThumbnail from "../components/VideoThumbnail";

const allowedPageSizes = [10, 20, "all"];
loadMessages(dictionary);
locale("vi");

const URL = `${BASE_URL}${VERSION}video`;
const videosData = useCustomStore(URL, {
  key: "id",
});

const VideoContainer = (props) => {
  const dataGridRef = useRef(null);

  videosData.setOptions({
    token: props.token,
    filter: { type: { equals: 0 } },
    include: [],
  });

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift({
      location: "after",
      widget: "dxButton",
      locateInMenu: "auto",
      options: {
        icon: "refresh",
        onClick: () => dataGridRef.current.instance.refresh(),
      },
    });
  };

  const imgCellRender = (data) => {
    if (!data.value) return <div></div>;

    return <img style={{ height: 50 }} src={`${data.value}`} />;
  };

  const onInitNewRow = (e) => {
    e.data.type = 0;
  };

  const thumbnailEditCellRender = (cell) => {
    return (
      <VideoThumbnail
        value={cell.value}
        setValue={(value) => cell.setValue(value)}
      />
    );
  };

  const urlSetCellValue = (newData, value) => {
    newData.url = value;
    newData.thumbnailUrl = tranformer.convertThumbnailYoutube(value);
  };

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="purple" contentPosition="none">
                <div className="w-full flex items-center justify-between">
                  <h2 className="text-white text-2xl">Quản lý Videos</h2>
                </div>
              </CardHeader>
              <CardBody>
                <DataGrid
                  id="grid"
                  showBorders={true}
                  ref={dataGridRef}
                  dataSource={videosData.store}
                  selection={{ mode: "single" }}
                  repaintChangesOnly={true}
                  remoteOperations={true}
                  allowColumnResizing={true}
                  onInitNewRow={onInitNewRow}
                  onToolbarPreparing={onToolbarPreparing}
                >
                  <Editing
                    refreshMode={"reshape"}
                    mode="form"
                    useIcons={true}
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                  />

                  <Paging defaultPageSize={20} />
                  <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={"full"}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />

                  <Column dataField="title" caption={formatMessage("title")}>
                    <RequiredRule />
                  </Column>
                  <Column
                    dataField="thumbnailUrl"
                    caption={formatMessage("thumbnail")}
                    allowSorting={false}
                    cellRender={imgCellRender}
                    editCellRender={thumbnailEditCellRender}
                  />
                  <Column
                    dataField="description"
                    caption={formatMessage("description")}
                  />
                  <Column
                    dataField="url"
                    caption={formatMessage("link")}
                    setCellValue={urlSetCellValue}
                  />
                  <Column
                    dataField="updatedAt"
                    caption={formatMessage("updatedAt")}
                    dataType="datetime"
                    editorOptions={{ readOnly: true }}
                    formItem={{ visible: false }}
                  />
                </DataGrid>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.data.token,
  };
};

VideoContainer.propTypes = {
  token: PropsType.string,
};

export default connect(mapStateToProps, null)(VideoContainer);
