import { createAction } from "redux-api-middleware";
import { UserVM } from "../model/User";
import { BASE_URL, VERSION } from "../utils/constants";

export const USER_CHECK = "@@user/USER_CHECK";

export const checkUser = () => {
  return { type: USER_CHECK };
};

export const USER_UPDATE_REQUEST = "@@user/USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "@@user/USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAILURE = "@@user/USER_UPDATE_FAILURE";

export const updateProfile = (token, userInfo) =>
  createAction({
    endpoint: `${BASE_URL}${VERSION}user/${userInfo.id}`,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(UserVM(userInfo)),
    types: [
      USER_UPDATE_REQUEST,
      { type: USER_UPDATE_SUCCESS, payload: userInfo },
      USER_UPDATE_FAILURE,
    ],
  });
