import React from "react";
import { DataGrid, Column, Editing } from "devextreme-react/data-grid";
import PropsType from "prop-types";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import { BASE_URL, PRODUCT_COMMENT_TYPE } from "../../utils/constants";
import { CommentVM } from "../../model/Comment";
import Card from "@material-tailwind/react/Card";
import CardBody from "@material-tailwind/react/CardBody";

const ProductCommentList = ({ token, productId }) => {
  const URL = `${BASE_URL}comment`;

  const searchRequest = () => {
    return fetch(`${URL}/${PRODUCT_COMMENT_TYPE}/post/${productId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok)
          return {
            data: json.data.map((c) => CommentVM(c)),
            totalCount: json.meta.page.total,
          };
        throw json.message;
      })
    );
  };

  const deleteRequest = (key) => {
    return fetch(`${URL}/${key}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          notify(
            {
              message: json.result,
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );
          return;
        }
        throw json.message;
      })
    );
  };

  const imgsList = new CustomStore({
    key: "id",
    load: () => searchRequest(),
    remove: (key) => deleteRequest(key),
  });

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24 mb-12">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardBody>
                <DataGrid
                  id="grid"
                  showBorders={true}
                  dataSource={imgsList}
                  selection={{ mode: "single" }}
                  repaintChangesOnly={true}
                >
                  <Editing
                    refreshMode={"reshape"}
                    mode="form"
                    useIcons={true}
                    allowDeleting={true}
                    allowUpdating={false}
                    allowAdding={false}
                  />

                  <Column dataField="id" editorOptions={{ readOnly: true }} />
                  <Column dataField="subject" dataType="string" />
                  <Column dataField="content" dataType="string" />
                  <Column dataField="customerName" dataType="string" />
                  <Column dataField="customerPhone" dataType="string" />
                  <Column dataField="rate" dataType="number" />
                  <Column dataField="updatedAt" dataType="datetime" />
                </DataGrid>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

ProductCommentList.propTypes = {
  token: PropsType.string,
  images: PropsType.array,
  productId: PropsType.number,
};

export default ProductCommentList;
