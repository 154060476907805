import React, { useEffect, useState } from "react";
import FileUploader from "devextreme-react/file-uploader";
import { BASE_URL, FILE_URL } from "../utils/constants";
import PropsType from "prop-types";
import "../styles/image-uploader.scss";
import { useHistory } from "react-router-dom";
var cookie = require("cookie-cutter");

const ImageUploader = ({ token, defaultValue = null, onImgUrlChange }) => {
  const history = useHistory();
  const [imgUrl, setImgUrl] = useState({});
  if (token === undefined) {
    token = cookie.get("token") || "";
  }

  useEffect(() => {
    setImgUrl(defaultValue);
  }, [defaultValue]);

  const getUploadedResponse = (e) => {
    const response = JSON.parse(e.request.response);

    setImgUrl(response.data[0]);
    onImgUrlChange(response.data[0]);
  };

  const getUploadErrorResponse = (e) => {
    if (e.error.status === 401) {
      history.push("/expiredToken");
    }
  };

  return (
    <div className="fileuploader-container">
      <FileUploader
        multiple={false}
        accept={"image/*"}
        uploadHeaders={{
          Authorization: "Bearer " + token,
        }}
        name={"files"}
        uploadMode={"instantly"}
        onUploaded={getUploadedResponse}
        onUploadError={getUploadErrorResponse}
        uploadUrl={`${BASE_URL}upload/multiple`}
      />

      {imgUrl && <img alt={""} src={`${FILE_URL}${imgUrl.path}`} />}
    </div>
  );
};

ImageUploader.propTypes = {
  token: PropsType.string,
  defaultValue: PropsType.object,
  onImgUrlChange: PropsType.func,
};

export default ImageUploader;
