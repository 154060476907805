import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  BASE_URL,
  FRIENDLY_GROUP_ID,
  FRIENDLY_GROUP_TYPE,
  PRODUCT_GROUP_ID,
  PRODUCT_TYPE,
  VERSION,
  HTML_ITEMS
} from "../utils/constants";
import { connect } from "react-redux";
import PropsType from "prop-types";
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
import Form, {
  ButtonItem,
  SimpleItem,
  RequiredRule,
  Label,
  GroupItem,
} from "devextreme-react/form";
import ProductInfoList from "../components/ProductInfoList";
import { formatMessage } from "devextreme/localization";
import TabPanel, { Item } from "devextreme-react/tab-panel";
import ImageUploader from "../components/ImageUploader";
import { ProductMV, ProductVM } from "../model/Product";
import ProductCommentList from "../components/product/ProductCommentList";
import useFetch from "../hook/useFetch";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import ProductVideoList from "../components/ProductVideoList";
import tranformer from "../utils/transformer";
import ProductImageList from "../components/ProductImageList";

var qs = require("qs");
var slugify = require("slugify");

const ProductDetailContainer = ({ token }) => {
  const history = useHistory();
  const { id } = useParams();
  const [product, setProduct] = useState({
    id: 0,
    images: [],
    info: [],
    category: { meta: {} },
    meta: [],
    thumbnail: {},
  });

  const categories = useFetch(`${BASE_URL}${VERSION}category`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  const brands = useFetch(`${BASE_URL}${VERSION}brand`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  const groups = useFetch(`${BASE_URL}${VERSION}group`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  const getProductDetail = (productId) => {
    const include = [
      "category",
      "thumbnail",
      "meta",
      "videos",
      "images",
      "comments",
      "groups"
    ];
    fetch(
      `${BASE_URL}${VERSION}product/${productId}?${qs.stringify({
        include,
      })}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          setProduct(ProductVM(json.data));
          return;
        }
        throw json.Message;
      })
    );
  };

  useEffect(() => {
    if (!id || !token) {
      return;
    }

    getProductDetail(id);
  }, [id, token]);

  const backButtonOptions = {
    type: "back",
    onClick: () => {
      history.push("/product");
    },
  };

  const handleSubmit = (e) => {
    if (product.id) {
      tranformer.fetchUpdate(`${BASE_URL}${VERSION}product/${product.id}`, {
        token,
        body: ProductMV(product),
        callback: () => { },
      });
    } else {
      tranformer.fetchCreate(`${BASE_URL}${VERSION}product`, {
        token,
        body: ProductMV(product),
        callback: (pId) => {
          history.push(`/product/${pId}`);
        },
      });
    }

    e.preventDefault();
  };

  const buttonOptions = {
    text: "Update",
    type: "success",
    useSubmitBehavior: true,
  };

  const categoriesSelectOptions = {
    items: categories.data,
    displayExpr: "name",
    searchEnabled: true,
    valueExpr: "id",
    readOnly: !!product.categoryId,
    onValueChanged: (args) => {
      if (args.value) {
        const selectedCategory = categories.data.find(
          (x) => x.id === args.value
        );
        if (selectedCategory) {
          setProduct({
            ...product,
            categoryId: args.value,
            type: selectedCategory.type,
          });
        } else {
          setProduct({
            ...product,
            categoryId: args.value,
          });
        }
      }
    },
  };

  const brandsSelectOptions = {
    items: brands.data.filter((x) => x.type === product.type),
    displayExpr: "name",
    searchEnabled: true,
    valueExpr: "id",
  };

  // const typeSelectOptions = {
  //   items: PRODUCT_TYPE,
  //   displayExpr: "name",
  //   searchEnabled: true,
  //   valueExpr: "id",
  // };

  const PRODUCT_GROUPS = groups.data
    .filter((x) => x.id !== PRODUCT_GROUP_ID)
    .filter((x) => PRODUCT_TYPE.map((x) => x.id).includes(x.type));

  const groupsSelectOptions = {
    items: PRODUCT_GROUPS,
    displayExpr: "name",
    placeholder: "Lựa chọn 1 hoặc nhiều nhóm sản phẩm",
    searchEnabled: true,
    valueExpr: "id",
  };

  const FRIENDLY_GROUPS = groups.data
    .filter((x) => x.id !== FRIENDLY_GROUP_ID)
    .filter((x) => x.type === FRIENDLY_GROUP_TYPE);

  const friendlyGroupSelectOptions = {
    items: FRIENDLY_GROUPS,
    displayExpr: "name",
    searchEnabled: true,
    valueExpr: "id",
    placeholder: "Lựa chọn nhóm hoặc nhập tên nhóm để thêm mới",
    acceptCustomValue: true,
    onCustomItemCreating: function (args) {
      if (!args.text) {
        args.customItem = null;
        return;
      }

      args.customItem = fetch(`${BASE_URL}${VERSION}group`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          name: args.text,
          type: FRIENDLY_GROUP_TYPE,
          key: tranformer.convertUnsignedChars(args.text),
          parent: { id: FRIENDLY_GROUP_ID },
        }),
      }).then((result) =>
        result
          .json()
          .then((json) => {
            return json.data;
          })
          .catch((error) => {
            throw error;
          })
      );
    },
  };

  const nameEditorOptions = {
    onValueChanged: (args) => {
      if (!id) {
        setProduct((prevState) => ({
          ...prevState,
          slug: slugify(`${args.value} ${product.sku || ""}`, {
            replacement: "-", // replace spaces with replacement character, defaults to `-`
            remove: undefined, // remove characters that match regex, defaults to `undefined`
            lower: true, // convert to lower case, defaults to `false`
            strict: false, // strip special characters except replacement, defaults to `false`
            locale: "vi", // language code of the locale to use
          }),
        }));
      }
    },
  };

  const skuEditorOptions = {
    onValueChanged: (args) => {
      if (!id) {
        setProduct((prevState) => ({
          ...prevState,
          slug: slugify(`${product.name || ""} ${args.value}`, {
            replacement: "-", // replace spaces with replacement character, defaults to `-`
            remove: undefined, // remove characters that match regex, defaults to `undefined`
            lower: true, // convert to lower case, defaults to `false`
            strict: false, // strip special characters except replacement, defaults to `false`
            locale: "vi", // language code of the locale to use
          }),
        }));
      }
    },
  };

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="purple" contentPosition="left">
                <h2 className="text-white text-2xl">Chi tiết sản phẩm</h2>
              </CardHeader>
              <CardBody>
                <Toolbar>
                  <ToolbarItem
                    location="before"
                    widget="dxButton"
                    options={backButtonOptions}
                  />
                  <ToolbarItem
                    location="center"
                    locateInMenu="never"
                    text={"Thông tin Product"}
                  />
                </Toolbar>

                <form action="your-action" onSubmit={handleSubmit}>
                  <Form
                    formData={product}
                    readOnly={false}
                    showColonAfterLabel={true}
                    colCount={3}
                    showValidationSummary={true}
                    validationGroup="customerData"
                  >
                    <GroupItem colSpan={1}>
                      <SimpleItem
                        dataField="categoryId"
                        editorType="dxSelectBox"
                        editorOptions={categoriesSelectOptions}
                      >
                        <Label text={formatMessage("category")} />
                        <RequiredRule />
                      </SimpleItem>

                      <SimpleItem visible={!!product.categoryId}>
                        <Label text={formatMessage("thumbnail")} />
                        <ImageUploader
                          defaultValue={product.thumbnail}
                          onImgUrlChange={(value) =>
                            setProduct({ ...product, thumbnail: value })
                          }
                          token={token}
                        />
                      </SimpleItem>
                    </GroupItem>

                    <GroupItem colSpan={2}>
                      <SimpleItem
                        dataField="sku"
                        editorType="dxTextBox"
                        editorOptions={skuEditorOptions}
                      // editorOptions={{ readOnly: !!product.id }}
                      >
                        <Label text={formatMessage("sku")} />
                        <RequiredRule />
                      </SimpleItem>

                      <SimpleItem
                        dataField="name"
                        editorType="dxTextBox"
                        editorOptions={nameEditorOptions}
                      >
                        <Label text={formatMessage("name")} />
                        <RequiredRule />
                      </SimpleItem>

                      <SimpleItem
                        dataField="slug"
                        editorType="dxTextBox"
                        editorOptions={{ readOnly: !!product.id }}
                      >
                        <Label text={formatMessage("slug")} />
                        <RequiredRule />
                      </SimpleItem>

                      {/* <SimpleItem
                        dataField="type"
                        editorType="dxSelectBox"
                        editorOptions={typeSelectOptions}
                      >
                        <Label text={formatMessage("type")} />
                      </SimpleItem> */}

                      <SimpleItem
                        dataField="brandId"
                        editorType="dxSelectBox"
                        visible={!!product.categoryId}
                        editorOptions={brandsSelectOptions}
                      >
                        <Label text={formatMessage("brand")} />
                      </SimpleItem>

                      <SimpleItem
                        dataField="groupId"
                        editorType="dxTagBox"
                        visible={!!product.categoryId}
                        editorOptions={groupsSelectOptions}
                      >
                        <Label text={formatMessage("group")} />
                      </SimpleItem>

                      <SimpleItem
                        dataField="friendlyGroupId"
                        editorType="dxSelectBox"
                        visible={!!product.categoryId}
                        editorOptions={friendlyGroupSelectOptions}
                      >
                        <Label text={formatMessage("friendlyGroup")} />
                      </SimpleItem>

                      <SimpleItem
                        dataField="price"
                        editorType="dxNumberBox"
                        editorOptions={{
                          format: { style: "currency", currency: "VND" },
                        }}
                        visible={!!product.categoryId}
                      >
                        <Label text={formatMessage("price")} />
                      </SimpleItem>

                      <SimpleItem
                        dataField="salePrice"
                        editorType="dxNumberBox"
                        editorOptions={{
                          format: { style: "currency", currency: "VND" },
                        }}
                        visible={!!product.categoryId}
                      >
                        <Label text={formatMessage("salePrice")} />
                      </SimpleItem>

                      <SimpleItem
                        dataField={"description"}
                        editorType="dxHtmlEditor"
                        editorOptions={{
                          toolbar: {
                            multiline: true,
                            items: HTML_ITEMS,
                          }
                        }}
                      >
                        <Label text={formatMessage("description")} />
                      </SimpleItem>
                    </GroupItem>

                    <ButtonItem
                      horizontalAlignment="center"
                      colSpan={3}
                      buttonOptions={buttonOptions}
                    />
                  </Form>
                </form>

                <div className="mt-8">
                  {!!product.id && (
                    <TabPanel id="service-note-tab">
                      <Item title="Thông tin Meta" icon="event">
                        <ProductInfoList product={product} token={token} />
                      </Item>

                      <Item title="Danh sách Videos" icon="video">
                        <ProductVideoList
                          videos={product.videos || []}
                          productId={product.id}
                          token={token}
                        />
                      </Item>

                      <Item title="Danh sách Ảnh sản phẩm" icon="photo">
                        <ProductImageList
                          images={product.images || []}
                          productId={product.id}
                          token={token}
                        />
                      </Item>

                      <Item title="Danh sách Comments" icon="tips">
                        <ProductCommentList
                          productId={product.id}
                          token={token}
                        />
                      </Item>
                    </TabPanel>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.data.token,
  };
};

ProductDetailContainer.propTypes = {
  token: PropsType.string,
};

export default connect(mapStateToProps, null)(ProductDetailContainer);
