import Form, {
  ButtonItem,
  SimpleItem,
  RequiredRule,
  Label,
  GroupItem,
} from "devextreme-react/form";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { PostMV, PostVM } from "../model/Post";
import React, { useEffect, useState } from "react";
import { formatMessage, locale, loadMessages } from "devextreme/localization";
import PropsType from "prop-types";
import { BASE_URL, POST_FILTER, VERSION, FILE_URL } from "../utils/constants";
import Toolbar, { Item } from "devextreme-react/toolbar";
import dictionary from "../data/dictionary";
import ImageUploader from "../components/ImageUploader";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import tranformer from "../utils/transformer";
import useFetch from "../hook/useFetch";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import CustomEditor from "@ckeditor/custom-build";
import cookie from 'cookie-cutter'

loadMessages(dictionary);
locale("vi");
var qs = require("qs");

function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}

function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        const body = new FormData();
        const access_token = cookie.get("token");

        loader.file.then((file) => {
          body.append("files", file);
          let headers = new Headers();
          headers.append("Authorization", `Bearer ${access_token}`);
          fetch(`${BASE_URL}upload/multiple`, {
            method: "post",
            body: body,
            headers: headers,
          })
            .then((res) => res.json())
            .then((res) => {
              resolve({
                default: `${FILE_URL}${res.data[0].path}`,
              });
            })
            .catch((err) => {
              reject(err);
            });
        });
      });
    },
  };
}

function PostDetailContainer({ token }) {
  const history = useHistory();
  const { id } = useParams();
  const [post, setPost] = useState({});
  const [editor, setEditor] = useState('');

  const tags = useFetch(`${BASE_URL}${VERSION}tag`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  useEffect(() => {
    if (!id || !token) {
      return;
    }

    const include = ["tags", "thumbnail"];
    fetch(`${BASE_URL}${VERSION}post/${id}?${qs.stringify({ include })}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          setPost(PostVM(json.data));
          setEditor(json.data?.content)
          return;
        }
        throw json.Message;
      })
    );
  }, [id, token]);

  const backButtonOptions = {
    type: "back",
    onClick: () => {
      history.push("/post");
    },
  };

  const buttonOptions = {
    text: "Công khai",
    type: "success",
    // useSubmitBehavior: true,
    onClick: () => handleSubmit({ ...post, status: 'publish' })
  };

  const handleSubmit = (post) => {
    post.content = editor
    if (post.id) {
      tranformer.fetchUpdate(`${BASE_URL}${VERSION}post/${post.id}`, {
        token: token,
        body: PostMV(post),
        callback: () => { },
      });
    } else {
      tranformer.fetchCreate(`${BASE_URL}${VERSION}post`, {
        token: token,
        body: PostMV(post),
        callback: () => { },
      });
    }
  };

  const saveAsDraft = () => handleSubmit({ ...post, status: 'draft' })

  const excerptOptions = {
    height: 200,
  };

  const groupSelectOptions = {
    items: POST_FILTER,
    displayExpr: "name",
    valueExpr: "value",
  };

  const titleEditorOptions = {
    onValueChanged: (args) => {
      if (!post.id)
        setPost((prevState) => ({
          ...prevState,
          slug: tranformer.convertUnsignedChars(args.value),
        }));
    },
  };

  const tagsOptions = {
    items: tags.data,
    displayExpr: "name",
    searchEnabled: true,
    valueExpr: "id",
    placeholder: "Lựa chọn tag hoặc nhập tên tag để thêm mới",
    acceptCustomValue: true,
    onCustomItemCreating: function (args) {
      if (!args.text) {
        args.customItem = null;
        return;
      }

      args.customItem = fetch(`${BASE_URL}${VERSION}tag`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          name: args.text,
        }),
      }).then((result) =>
        result
          .json()
          .then((json) => {
            return json.data;
          })
          .catch((error) => {
            throw error;
          })
      );
    },
  };

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="purple" contentPosition="left">
                <h2 className="text-white text-2xl">Chi tiết bài viết</h2>
              </CardHeader>
              <CardBody>
                <Toolbar>
                  <Item
                    location="before"
                    widget="dxButton"
                    options={backButtonOptions}
                  />
                  <Item
                    location="center"
                    text={`${formatMessage("info")} ${formatMessage(
                      "of"
                    )} ${formatMessage("post")}`}
                  />
                </Toolbar>

                <form action="your-action" onSubmit={handleSubmit}>
                  <Form
                    formData={post}
                    readOnly={false}
                    showColonAfterLabel={true}
                    colCount={1}
                    showValidationSummary={true}
                    validationGroup="postData"
                  >
                    <GroupItem>
                      <SimpleItem
                        dataField={"title"}
                        editorType="dxTextBox"
                        editorOptions={titleEditorOptions}
                      >
                        <Label text={formatMessage("title")} />
                      </SimpleItem>

                      <SimpleItem
                        dataField={"slug"}
                        editorOptions={{ readOnly: !!post.id }}
                      >
                        <Label text={formatMessage("slug")} />
                        <RequiredRule />
                      </SimpleItem>

                      <SimpleItem
                        dataField={"groupId"}
                        editorType="dxSelectBox"
                        editorOptions={groupSelectOptions}
                      >
                        <Label text={formatMessage("group")} />
                        <RequiredRule />
                      </SimpleItem>

                      <SimpleItem
                        dataField="tags"
                        editorType="dxTagBox"
                        editorOptions={tagsOptions}
                      >
                        <Label text={formatMessage("tags")} />
                      </SimpleItem>

                      <GroupItem colCount={2}>
                        <SimpleItem
                          dataField={"excerpt"}
                          editorType="dxTextArea"
                          editorOptions={excerptOptions}
                        >
                          <Label text={formatMessage("excerpt")} />
                          <RequiredRule />
                        </SimpleItem>

                        <SimpleItem>
                          <Label text={formatMessage("thumbnail")} />
                          <ImageUploader
                            defaultValue={post.thumbnail}
                            onImgUrlChange={(value) =>
                              setPost({ ...post, thumbnail: value })
                            }
                            token={token}
                          />
                        </SimpleItem>
                      </GroupItem>

                      <SimpleItem
                        dataField={"content"}
                        render={() => (
                          <CKEditor
                            editor={CustomEditor}
                            config={{
                              extraPlugins: [uploadPlugin],
                              mediaEmbed: {
                                previewsInData: true
                              }
                            }}
                            data={editor}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setEditor(data)
                            }}
                          />
                        )}
                      >
                        <Label text={formatMessage("content")} />
                        <RequiredRule />
                      </SimpleItem>
                    </GroupItem>

                    <GroupItem colCount={2}>
                      <ButtonItem
                        horizontalAlignment="right"
                        buttonOptions={{
                          text: "Lưu nháp",
                          type: "button",
                          onClick: saveAsDraft
                        }}
                      />

                      <ButtonItem
                        horizontalAlignment="left"
                        buttonOptions={buttonOptions}
                      />
                    </GroupItem>

                  </Form>
                </form>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.data.token,
  };
};

PostDetailContainer.propTypes = {
  token: PropsType.string,
};

export default connect(mapStateToProps, null)(PostDetailContainer);
