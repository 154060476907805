import React, { useRef } from "react";
import { connect } from "react-redux";
import PropsType from "prop-types";
import {
  DataGrid,
  Column,
  Editing,
  Pager,
  Paging,
  Lookup,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  RequiredRule,
} from "devextreme-react/data-grid";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../data/dictionary";
import {
  BASE_URL,
  DISCOVERY_DOSECO,
  FILE_URL,
  PRODUCT_TYPE,
  VERSION,
} from "../utils/constants";
import "devextreme-react/text-area";
import ImageUploader from "../components/ImageUploader";
import useCustomStore from "../hook/useCustomStore";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import useFetch from "../hook/useFetch";
// import { Label, SimpleItem } from "devextreme-react/form";
import TagBox from 'devextreme-react/tag-box'

const allowedPageSizes = [10, 20, "all"];
loadMessages(dictionary);
locale("vi");
var qs = require("qs");

const URL = `${BASE_URL}${VERSION}brand`;
const brandsData = useCustomStore(URL, {
  key: "id",
});

function BrandContainer(props) {
  const dataGridRef = useRef(null);

  const groups = useFetch(`${BASE_URL}${VERSION}group?${qs.stringify({
    filter: {
      parent: {
        key: DISCOVERY_DOSECO
      }
    }
  })}`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${props.token}`,
      "Access-Control-Allow-Origin": "*",
    },

  });

  brandsData.setOptions({
    token: props.token,
    include: ["logo", "groups"],
  });

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift({
      location: "after",
      widget: "dxButton",
      locateInMenu: "auto",
      options: {
        icon: "refresh",
        onClick: () => dataGridRef.current.instance.refresh(),
      },
    });
  };

  const imgCellRender = (data) => {
    return <img style={{ height: 30 }} src={`${FILE_URL}${data.value.path}`} />;
  };

  const imgEditCellRender = (cell) => {
    return (
      <ImageUploader
        defaultValue={cell.value}
        onImgUrlChange={(value) => cell.setValue(value)}
        token={props.token}
      />
    );
  };

  const groupsEditCellRender = (cell) => (
    <TagBox
      items={groups.data}
      displayExpr={"name"}
      placeholder={"Lựa chọn 1 hoặc nhiều nhóm sản phẩm"}
      searchEnabled={true}
      valueExpr={"id"}
      defaultValue={cell.value && Array.isArray(cell.value) ? cell.value.map(item => item.id) : undefined}
      onValueChanged={({ value }) => cell.setValue(value && Array.isArray(value) ? value.map(id => ({ id })) : undefined)}
    />
  )

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="purple" contentPosition="none">
                <div className="w-full flex items-center justify-between">
                  <h2 className="text-white text-2xl">Danh sách thương hiệu</h2>
                </div>
              </CardHeader>
              <CardBody>
                <DataGrid
                  id="grid"
                  showBorders={true}
                  ref={dataGridRef}
                  dataSource={brandsData.store}
                  selection={{ mode: "single" }}
                  repaintChangesOnly={true}
                  allowColumnResizing={true}
                  remoteOperations={true}
                  onToolbarPreparing={onToolbarPreparing}
                >
                  <FilterRow visible={true} applyFilter={true} />
                  <FilterPanel visible={true} />
                  <HeaderFilter visible={true} />
                  <Editing
                    refreshMode={"reshape"}
                    mode="form"
                    useIcons={true}
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                  />

                  <Paging defaultPageSize={20} />
                  <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={"full"}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />

                  <Column dataField="name" caption={formatMessage("name")}>
                    <RequiredRule />
                  </Column>
                  <Column dataField="type" caption={formatMessage("type")}>
                    <RequiredRule />
                    <Lookup
                      dataSource={PRODUCT_TYPE}
                      valueExpr="value"
                      displayExpr="name"
                    />
                  </Column>
                  <Column
                    dataField="logo"
                    caption={formatMessage("logo")}
                    allowSorting={false}
                    allowFiltering={false}
                    cellRender={imgCellRender}
                    editCellRender={imgEditCellRender}
                  >
                    <RequiredRule />
                  </Column>
                  <Column
                    dataField="groups"
                    caption={formatMessage("group")}
                    allowSorting={false}
                    allowFiltering={false}
                    visible={false}
                    editCellRender={groupsEditCellRender}
                  >
                  </Column>
                  <Column
                    dataField="updatedAt"
                    caption={formatMessage("updatedAt")}
                    dataType="datetime"
                    formItem={{ visible: false }}
                  />
                </DataGrid>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.data.token,
  };
};

BrandContainer.propTypes = {
  token: PropsType.string,
};

export default connect(mapStateToProps, null)(BrandContainer);
