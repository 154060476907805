import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import PropsType from "prop-types";
import {
  DataGrid,
  Column,
  Editing,
  RequiredRule,
  Pager,
  Paging,
  HeaderFilter,
  FilterPanel,
  FilterRow,
  Lookup,
  Button as GridButton,
} from "devextreme-react/data-grid";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../data/dictionary";
import { BASE_URL, VERSION } from "../utils/constants";
import { useHistory } from "react-router-dom";
import useFetch from "../hook/useFetch";
import useCustomStore from "../hook/useCustomStore";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Button from "@material-tailwind/react/Button";
import ProductImportExcel from "../components/product/ProductImportExcel";

const allowedPageSizes = [10, 20, 50];
loadMessages(dictionary);
locale("vi");

const URL = `${BASE_URL}${VERSION}product`;
const productsData = useCustomStore(URL, {
  key: "id",
});

const ProductContainer = (props) => {
  const [group, setGroup] = useState(null);
  const [importPopup, setImportPopup] = useState(false);
  const dataGridRef = useRef(null);
  const history = useHistory();

  productsData.setOptions({
    token: props.token,
    filter: group ? { groups: { some: { id: { equals: group } } } } : {},
    include: ["category", "brand", "groups"],
    history: history,
  });

  const categories = useFetch(`${BASE_URL}${VERSION}category`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${props.token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  const brands = useFetch(`${BASE_URL}${VERSION}brand?distinct=name`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${props.token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  const groups = useFetch(`${BASE_URL}${VERSION}group`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${props.token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        widget: "dxButton",
        locateInMenu: "auto",
        options: {
          icon: "refresh",
          onClick: () => dataGridRef.current.instance.refresh(),
        },
      },
      {
        location: "before",
        widget: "dxButton",
        locateInMenu: "auto",
        options: {
          text: "Import SP",
          onClick: () => setImportPopup(true),
        },
      },
      {
        location: "after",
        widget: "dxSelectBox",
        locateInMenu: "auto",
        options: selectBoxGroup,
      }
    );
  };

  const onRowDblClick = (e) => {
    history.push(`/product/${e.key}`);
  };

  const selectBoxGroup = {
    items: groups.data,
    displayExpr: "name",
    valueExpr: "id",
    value: group,
    width: 250,
    placeholder: "Nhóm",
    showClearButton: true,
    onValueChanged: (args) => setGroup(args.value),
  };

  const editButtonRender = (e) => {
    return (
      <i
        className="dx-icon dx-icon-edit"
        onClick={() => history.push(`/product/${e.key}`)}
        style={{ fontSize: 18, color: "#337ab7" }}
      />
    );
  };

  const hideImagePopup = () => setImportPopup(false);
  const refreshList = () => dataGridRef.current.instance.refresh();

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="purple" contentPosition="none">
                <div className="w-full flex items-center justify-between">
                  <h2 className="text-white text-2xl">Danh sách sản phẩm</h2>
                  <Button
                    color="transparent"
                    buttonType="link"
                    size="lg"
                    style={{ padding: 0 }}
                    onClick={() => history.push(`/product/add`)}
                  >
                    Thêm mới
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <DataGrid
                  id="id"
                  ref={dataGridRef}
                  showBorders={true}
                  dataSource={productsData.store}
                  selection={{ mode: "single" }}
                  columnResizingMode={"widget"}
                  repaintChangesOnly={true}
                  allowColumnResizing={true}
                  remoteOperations={true}
                  onRowDblClick={onRowDblClick}
                  onToolbarPreparing={onToolbarPreparing}
                >
                  <Editing
                    mode="form"
                    useIcons={true}
                    allowAdding={false}
                    allowDeleting={true}
                  />

                  <Paging defaultPageSize={20} />
                  <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={"full"}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />

                  <FilterRow visible={true} />
                  <FilterPanel visible={true} />
                  <HeaderFilter visible={true} />
                  <Column
                    dataField="sku"
                    caption={formatMessage("sku")}
                    allowFiltering={true}
                    allowHeaderFiltering={false}
                  />
                  <Column dataField="name" caption={formatMessage("name")}>
                    <RequiredRule />
                  </Column>
                  <Column
                    dataField="category.name"
                    caption={formatMessage("category")}
                  >
                    <Lookup
                      dataSource={categories.data}
                      valueExpr="name"
                      displayExpr="name"
                    />
                  </Column>
                  <Column
                    dataField="brand.name"
                    caption={formatMessage("brand")}
                  >
                    <Lookup
                      dataSource={brands.data}
                      valueExpr="name"
                      displayExpr="name"
                    />
                  </Column>
                  <Column
                    allowEditing={false}
                    dataField="slug"
                    caption={formatMessage("slug")}
                  />
                  <Column
                    dataField="price"
                    caption={formatMessage("price")}
                    dataType="number"
                    format={{ style: "currency", currency: "VND" }}
                    allowHeaderFiltering={false}
                  />
                  <Column
                    dataField="salePrice"
                    caption={formatMessage("salePrice")}
                    dataType="number"
                    format={{ style: "currency", currency: "VND" }}
                    allowHeaderFiltering={false}
                  />
                  <Column
                    dataField="createdAt"
                    caption={formatMessage("createdAt")}
                    dataType="datetime"
                    allowHeaderFiltering={false}
                  />

                  <Column type="buttons">
                    <GridButton render={editButtonRender} />
                    <GridButton name="delete" />
                  </Column>
                </DataGrid>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>

      <ProductImportExcel
        visible={importPopup}
        hidePopup={hideImagePopup}
        token={props.token}
        refreshList={refreshList}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.data.token,
    lang: state.setting.language.currentLang,
  };
};

ProductContainer.propTypes = {
  token: PropsType.string,
  lang: PropsType.string,
};

export default connect(mapStateToProps, null)(ProductContainer);
