import React, { useRef } from "react";
import { connect } from "react-redux";
import PropsType from "prop-types";
import {
  TreeList,
  Editing,
  Column,
  ValidationRule,
  Paging,
  Pager,
  Lookup,
  FilterRow,
  HeaderFilter,
  Button,
} from "devextreme-react/tree-list";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../data/dictionary";
import { BASE_URL, FILE_URL, PRODUCT_TYPE, VERSION } from "../utils/constants";
import { useHistory } from "react-router-dom";
import ImageUploader from "../components/ImageUploader";
import useCustomStore from "../hook/useCustomStore";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";

const allowedPageSizes = [10, 20, "all"];
loadMessages(dictionary);
locale("vi");

const URL = `${BASE_URL}${VERSION}category`;
const categoriesData = useCustomStore(URL, {
  key: "id",
});

const CategoryContainer = (props) => {
  const dataGridRef = useRef(null);
  const history = useHistory();

  categoriesData.setOptions({
    token: props.token,
    filter: {},
    include: ["thumbnail"],
  });

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;

    // Modifies an existing item
    toolbarItems.forEach(function (item) {
      if (item.name === "addRowButton") {
        item.options = {
          icon: "plus",
          onClick: function () {
            history.push("/category/add");
          },
        };
      }
    });

    toolbarItems.unshift({
      location: "after",
      widget: "dxButton",
      locateInMenu: "auto",
      options: {
        icon: "refresh",
        onClick: () => dataGridRef.current.instance.refresh(),
      },
    });
  };

  const addMenuItems = (e) => {
    if (e.target === "content") {
      // e.items can be undefined
      if (!e.items) e.items = [];

      // Add a custom menu item
      e.items.push({
        text: "Chi tiết Category",
        icon: "info",
        onItemClick: () => {
          history.push(`/category/${e.row.id}`);
        },
      });
    }
  };

  const onRowDblClick = (e) => {
    history.push(`/category/${e.data.id}`);
  };

  const imgCellRender = (data) => {
    if (!data.value) {
      return <div></div>;
    }

    return (
      <img
        style={{ height: 30 }}
        src={`${FILE_URL}${data.value.path}`}
      />
    );
  };

  const imgEditCellRender = (cell) => {
    return (
      <ImageUploader
        defaultValue={cell.value}
        onImgUrlChange={(value) => cell.setValue(value)}
        token={props.token}
      />
    );
  };

  const viewButtonClick = (e) => {
    history.push(`/category/${e.row.data.id}`);
  };

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="purple" contentPosition="none">
                <div className="w-full flex items-center justify-between">
                  <h2 className="text-white text-2xl">Quản lý danh mục</h2>
                </div>
              </CardHeader>
              <CardBody>
                <TreeList
                  id="id"
                  ref={dataGridRef}
                  showBorders={true}
                  dataSource={categoriesData.store}
                  repaintChangesOnly={true}
                  keyExpr="id"
                  parentIdExpr="parentId"
                  selection={{ mode: "single" }}
                  allowColumnResizing={true}
                  autoExpandAll={true}
                  onRowDblClick={onRowDblClick}
                  onContextMenuPreparing={addMenuItems}
                  onToolbarPreparing={onToolbarPreparing}
                >
                  <FilterRow visible={true} applyFilter={true} />
                  <HeaderFilter visible={true} />
                  <Editing
                    refreshMode={"reshape"}
                    mode="form"
                    useIcons={true}
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                  />

                  <Paging defaultPageSize={20} />
                  <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={"full"}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />

                  <Column dataField="name" caption={formatMessage("name")}>
                    <ValidationRule type="required" />
                  </Column>
                  <Column
                    dataField="thumbnail"
                    caption={formatMessage("thumbnail")}
                    allowSorting={false}
                    cellRender={imgCellRender}
                    editCellRender={imgEditCellRender}
                  />
                  <Column dataField="type" caption={formatMessage("type")}>
                    <ValidationRule type="required" />
                    <Lookup
                      dataSource={PRODUCT_TYPE}
                      valueExpr="value"
                      displayExpr="name"
                    />
                  </Column>
                  <Column
                    dataField="key"
                    caption={formatMessage("key")}
                    dataType="string"
                  />
                  <Column
                    dataField="userManual"
                    caption={formatMessage("userManual")}
                    allowEditing={false}
                  />
                  <Column
                    dataField="insurance"
                    caption={formatMessage("insurance")}
                    allowEditing={false}
                  />

                  <Column type="buttons">
                    <Button name="edit" onClick={viewButtonClick} />
                    <Button name="delete" />
                  </Column>
                </TreeList>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.data.token,
    lang: state.setting.language.currentLang,
  };
};

CategoryContainer.propTypes = {
  token: PropsType.string,
  lang: PropsType.string,
};

export default connect(mapStateToProps, null)(CategoryContainer);
