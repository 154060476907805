const PromotionVM = (data) => {
  const returnData = Object.assign({}, data);

  return returnData;
};

const PromotionMV = (data) => {
  return {
    name: data.name,
    description: data.description,
    note: data.note,
    active: data.active,
    startAt: data.startAt,
    endAt: data.endAt,
  };
};

export { PromotionVM, PromotionMV };
