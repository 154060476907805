import React, { useEffect, useRef, useState } from "react";
import {
  DataGrid,
  Column,
  Lookup,
  Button,
  Editing,
} from "devextreme-react/data-grid";
import PropTypes from "prop-types";
import { BASE_URL, COUPON_TYPE, VERSION } from "../../utils/constants";
import CouponForm from "./CouponForm";
import { Popup } from "devextreme-react/popup";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../data/dictionary";
import { ScrollView } from "devextreme-react";
import tranformer from "../../utils/transformer";
import { CouponVM } from "../../model/Coupon";

loadMessages(dictionary);
locale("vi");

var qs = require("qs");

function CouponsList({ promotionId, token }) {
  const dataGridRef = useRef(null);
  const [showItemPopup, setShowItemPopup] = useState(false);
  const [selectCouponId, setSelectCouponId] = useState(null);
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    refreshCoupons();
  }, [promotionId]);

  const refreshCoupons = () => {
    if (!promotionId) {
      return;
    }

    const include = ["coupons"];
    fetch(
      `${BASE_URL}${VERSION}promotion/${promotionId}?${qs.stringify({
        include,
      })}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          setCoupons(json.data.coupons.map((item) => CouponVM(item)));
        }
      })
    );
  };

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.push({
      location: "after",
      widget: "dxButton",
      locateInMenu: "auto",
      disabled: !promotionId,
      options: {
        icon: "add",
        onClick: () => setShowItemPopup(true),
      },
    });
  };

  const discountCellRender = (cell) => {
    let discountTxt = "";
    if (cell.data.isPercent) {
      discountTxt = `${cell.value * 100}%`;
    } else {
      discountTxt = `${tranformer.formatCurrency(cell.value)}`;
    }
    return <div>{discountTxt}</div>;
  };

  const editCoupon = (e) => {
    setSelectCouponId(e.row.data.id);
    setShowItemPopup(true);
  };

  const closeCouponForm = () => {
    console.log("closeCouponForm");
    setShowItemPopup(false);
    setSelectCouponId(null);
  };

  const validateRemove = async (e) => {
    const isCanceled = async () => {
      const validationResult = await fetch(
        `${BASE_URL}${VERSION}coupon/${e.data.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      if (validationResult.status !== 200) {
        return true;
      } else {
        return false;
      }
    };
    e.cancel = await isCanceled();
  };

  return (
    <React.Fragment>
      <DataGrid
        ref={dataGridRef}
        key="id"
        dataSource={coupons}
        showBorders={true}
        selection={{ mode: "single" }}
        onRowRemoving={validateRemove}
        onToolbarPreparing={onToolbarPreparing}
      >
        <Editing
          refreshMode={"reshape"}
          mode="popup"
          useIcons={true}
          allowAdding={false}
          allowDeleting={true}
          allowUpdating={true}
        />
        <Column dataField="code" caption={formatMessage("code")} />
        <Column
          dataField="description"
          caption={formatMessage("description")}
        />

        <Column
          dataField="isPercent"
          dataType="boolean"
          caption={formatMessage("isPercent")}
        />

        <Column
          dataField="discount"
          cellRender={discountCellRender}
          caption={formatMessage("discount")}
        />

        <Column dataField="type" caption={formatMessage("type")}>
          <Lookup
            dataSource={COUPON_TYPE}
            valueExpr="value"
            displayExpr="name"
          />
        </Column>

        <Column type="buttons">
          <Button icon="info" onClick={editCoupon} />
          <Button name="delete" />
        </Column>
      </DataGrid>

      <Popup
        visible={showItemPopup}
        onHiding={closeCouponForm}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        title="Thêm mới Mã KM"
        container=".dx-viewport"
      >
        <ScrollView width="100%" height="100%">
          <CouponForm
            promotionId={promotionId}
            couponId={selectCouponId}
            token={token}
            hidePopup={closeCouponForm}
            refreshCoupons={refreshCoupons}
          />
        </ScrollView>
      </Popup>
    </React.Fragment>
  );
}

CouponsList.propTypes = {
  token: PropTypes.string,
  promotionId: PropTypes.number,
};

export default CouponsList;
