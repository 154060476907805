import Form, {
  SimpleItem,
  RequiredRule,
  Label,
  GroupItem,
  ButtonItem,
} from "devextreme-react/form";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { formatMessage, locale, loadMessages } from "devextreme/localization";
import PropsType from "prop-types";
import { BASE_URL, VERSION } from "../utils/constants";
import Toolbar, { Item } from "devextreme-react/toolbar";
import dictionary from "../data/dictionary";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import tranformer from "../utils/transformer";
import useFetch from "../hook/useFetch";

loadMessages(dictionary);
locale("vi");
var qs = require("qs");

function UserVM(data) {
  const returnData = Object.assign({}, data);
  returnData.roles = data.roles && data.roles.length ? data.roles.map((x) => x.id) : [];

  return returnData;
}

function UserMV(data) {
  return {
    username: data.username,
    email: data.email,
    fullName: data.fullName,
    password: data.password,
    roles: data.roles
      ? data.roles.map((x) => {
        return { id: x };
      })
      : undefined,
  }
}

function AccountDetailContainer({ token }) {
  const history = useHistory();
  const { id } = useParams();
  const [user, setUser] = useState({});

  const roles = useFetch(`${BASE_URL}${VERSION}role`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  useEffect(() => {
    if (!id || !token) {
      return;
    }

    fetch(`${BASE_URL}${VERSION}user/${id}?${qs.stringify({ include: ['roles'] })}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          setUser(UserVM(json.data));
          return;
        }
        throw json.Message;
      })
    );
  }, [id, token]);

  const backButtonOptions = {
    type: "back",
    onClick: () => {
      history.push("/user");
    },
  };

  const rolesOptions = {
    items: roles.data,
    displayExpr: "name",
    searchEnabled: true,
    valueExpr: "id",
    placeholder: "Lựa chọn quyền để thêm",
    acceptCustomValue: false,
  }

  const handleSubmit = (e) => {
    if (user.id) {
      tranformer.fetchUpdate(`${BASE_URL}user/${user.id}`, {
        token: token,
        body: UserMV(user),
        callback: () => { },
      });
    } else {
      tranformer.fetchCreate(`${BASE_URL}user`, {
        token: token,
        body: UserMV(user),
        callback: () => { },
      });
    }

    e.preventDefault();
  };
  console.log('user: ', user)

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="purple" contentPosition="left">
                <h2 className="text-white text-2xl">Chi tiết tài khoản</h2>
              </CardHeader>
              <CardBody>
                <Toolbar>
                  <Item
                    location="before"
                    widget="dxButton"
                    options={backButtonOptions}
                  />
                  <Item
                    location="center"
                    text={`${formatMessage("info")} ${formatMessage(
                      "of"
                    )} ${formatMessage("user")}`}
                  />
                </Toolbar>

                <form action="your-action" onSubmit={handleSubmit}>
                  <Form
                    formData={user}
                    readOnly={false}
                    showColonAfterLabel={true}
                    colCount={1}
                    showValidationSummary={true}
                    validationGroup="userData"
                  >
                    <GroupItem>
                      <SimpleItem
                        dataField={"username"}
                      >
                        <Label text={formatMessage("username")} />
                        <RequiredRule />
                      </SimpleItem>
                      <SimpleItem
                        dataField={"fullName"}
                      >
                        <Label text={formatMessage("fullName")} />
                        <RequiredRule />
                      </SimpleItem>
                      <SimpleItem
                        dataField={"email"}
                      >
                        <Label text={formatMessage("email")} />
                      </SimpleItem>
                      <SimpleItem
                        dataField={"password"}
                        visible={!id}
                      >
                        <Label text={formatMessage("password")} />
                        <RequiredRule />
                      </SimpleItem>

                      <SimpleItem
                        dataField="roles"
                        editorType="dxTagBox"
                        editorOptions={rolesOptions}
                      >
                        <Label text={formatMessage("roleName")} />
                        <RequiredRule />
                      </SimpleItem>
                    </GroupItem>

                    <ButtonItem
                      horizontalAlignment="center"
                      buttonOptions={{
                        text: "Update",
                        type: "success",
                        useSubmitBehavior: true,
                      }}
                    />
                  </Form>
                </form>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.data.token,
  };
};

AccountDetailContainer.propTypes = {
  token: PropsType.string,
};

export default connect(mapStateToProps, null)(AccountDetailContainer);