import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import PropsType from "prop-types";
import {
  DataGrid,
  Column,
  Pager,
  Paging,
  Editing,
  FilterPanel,
  FilterRow,
  Button as GridButton,
} from "devextreme-react/data-grid";
import { Link, useHistory } from "react-router-dom";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../data/dictionary";
import { BASE_URL, VERSION } from "../utils/constants";
import "devextreme-react/text-area";
import useCustomStore from "../hook/useCustomStore";
import viMessages from "devextreme/localization/messages/vi.json";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Button from "@material-tailwind/react/Button";
import ChangePassPopup from "../components/account/ChangePassPopup";
import useFetch from "../hook/useFetch";

const allowedPageSizes = [10, 20, "all"];
loadMessages(dictionary);
loadMessages(viMessages);
locale("vi");

const URL = `${BASE_URL}${VERSION}user`;
const usersData = useCustomStore(URL, {
  key: "id",
});

// const roleCellRender = ({ value }) => {
//   return value && value.length ? value.map(item => item?.name).join(', ') : ''
// }

const AccountContainer = (props) => {
  const history = useHistory();
  const dataGridRef = useRef(null);

  const [role, setRole] = useState(null);
  const [changePassPopup, setChangePassPopup] = useState(false);
  const [userId, setUserId] = useState(null);

  usersData.setOptions({
    token: props.token,
    filter: role ? { roles: { some: { id: { equals: role } } } } : {},
    history
  });

  const roles = useFetch(`${BASE_URL}${VERSION}role`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${props.token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  const onChangePass = (e) => {
    setUserId(e?.row?.data?.id);
    setChangePassPopup(true);
  };

  const hideChangePassPopup = () => setChangePassPopup(false);

  const onRowDblClick = (e) => {
    history.push(`/user/${e.key}`);
  };

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxSelectBox",
        locateInMenu: "auto",
        options: {
          items: roles.data,
          displayExpr: "name",
          valueExpr: "id",
          value: role,
          width: 250,
          placeholder: "Quyền",
          showClearButton: true,
          onValueChanged: (args) => {
            setRole(args.value);
            dataGridRef.current.instance.refresh()
          },
        },
      }
    );
  };

  const editButtonRender = (e) => {
    return (
      <Link to={`/user/${e.key}`}>
        <i
          className="dx-icon dx-icon-edit"
          style={{ fontSize: 18, color: "#337ab7" }}
        />
      </Link>
    );
  };

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="purple" contentPosition="none">
                <div className="w-full flex items-center justify-between">
                  <h2 className="text-white text-2xl">Danh sách tài khoản</h2>
                  <Button
                    color="transparent"
                    buttonType="link"
                    size="lg"
                    style={{ padding: 0 }}
                    onClick={() => history.push(`/user/add`)}
                  >
                    Thêm mới
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <DataGrid
                  id="grid"
                  ref={dataGridRef}
                  showBorders={true}
                  dataSource={usersData.store}
                  selection={{ mode: "single" }}
                  repaintChangesOnly={true}
                  allowColumnResizing={true}
                  remoteOperations={true}
                  onRowDblClick={onRowDblClick}
                  onToolbarPreparing={onToolbarPreparing}
                >
                  <FilterRow visible={true} />
                  <FilterPanel visible={true} />

                  <Editing
                    useIcons={true}
                    allowDeleting={true}
                  />

                  <Paging defaultPageSize={20} />
                  <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={"full"}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />

                  <Column
                    dataField="username"
                    caption={formatMessage("username")}
                  />
                  <Column dataField="email" caption={formatMessage("email")} />
                  <Column
                    dataField="fullName"
                    caption={formatMessage("fullName")}
                  />
                  <Column
                    dataField="password"
                    caption={formatMessage("password")}
                    visible={false}
                  />
                  {/* <Column
                    dataField="roles"
                    caption={formatMessage("roleName")}
                    cellRender={roleCellRender}
                  >
                  </Column> */}
                  <Column
                    dataField="createdAt"
                    caption={formatMessage("createdAt")}
                    dataType="datetime"
                  />

                  <Column type="buttons">
                    <GridButton render={editButtonRender} />
                    <GridButton
                      hint={"Đổi mật khẩu"}
                      icon="revert"
                      onClick={onChangePass}
                    />
                    <GridButton name="delete" />
                  </Column>
                </DataGrid>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>

      <ChangePassPopup
        visible={changePassPopup}
        hidePopup={hideChangePassPopup}
        token={props.token}
        userId={userId}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.data.token,
  };
};

AccountContainer.propTypes = {
  token: PropsType.string,
};

export default connect(mapStateToProps, null)(AccountContainer);
