import React, { useRef } from "react";
import {
  DataGrid,
  Column,
  Editing,
  RequiredRule,
  NumericRule,
  Pager,
  Paging,
  Lookup,
  Grouping,
} from "devextreme-react/data-grid";
import { connect } from "react-redux";
import PropsType from "prop-types";
import { BASE_URL, FILE_URL, pages, VERSION } from "../utils/constants";
import { formatMessage } from "devextreme/localization";
import "../styles/banner-container.scss";
import ImageUploader from "../components/ImageUploader";
import useCustomStore from "../hook/useCustomStore";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";

const allowedPageSizes = [10, 20, "all"];

const URL = `${BASE_URL}${VERSION}banner`;
const bannersData = useCustomStore(URL, {
  key: "id",
});

const BannerContainer = ({ token }) => {
  const dataGridRef = useRef(null);

  bannersData.setOptions({
    token: token,
    filter: {},
    include: ["image"],
  });

  const imgCellRender = (data) => {
    return (
      <img
        style={{ height: 30 }}
        src={`${FILE_URL}${data.value.path}`}
      />
    );
  };

  const imgEditCellRender = (cell) => {
    return (
      <ImageUploader
        defaultValue={cell.value}
        onImgUrlChange={(value) => cell.setValue(value)}
        token={token}
      />
    );
  };

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift({
      location: "after",
      widget: "dxButton",
      locateInMenu: "auto",
      options: {
        icon: "refresh",
        onClick: () => dataGridRef.current.instance.refresh(),
      },
    });
  };

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="purple" contentPosition="none">
                <div className="w-full flex items-center justify-between">
                  <h2 className="text-white text-2xl">Quản lý Banner</h2>
                </div>
              </CardHeader>
              <CardBody>
                <DataGrid
                  id="grid"
                  showBorders={true}
                  ref={dataGridRef}
                  dataSource={bannersData.store}
                  repaintChangesOnly={true}
                  selection={{ mode: "single" }}
                  allowColumnResizing={true}
                  onToolbarPreparing={onToolbarPreparing}
                >
                  <Grouping autoExpandAll={false} />
                  <Editing
                    refreshMode={"reshape"}
                    mode="form"
                    useIcons={true}
                    allowAdding={true}
                    allowDeleting={true}
                    allowUpdating={true}
                  />

                  <Paging defaultPageSize={20} />
                  <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={"full"}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />

                  <Column
                    dataField="page"
                    width={125}
                    caption={formatMessage("page")}
                    groupIndex={0}
                  >
                    <RequiredRule />
                    <Lookup
                      dataSource={pages}
                      valueExpr="name"
                      displayExpr="name"
                    />
                  </Column>

                  <Column dataField="link" caption={formatMessage("link")} />

                  <Column dataField="title" caption={formatMessage("title")} />

                  <Column
                    dataField="position"
                    width={150}
                    caption={formatMessage("position")}
                  >
                    <NumericRule />
                  </Column>

                  <Column
                    dataField="image"
                    caption={formatMessage("image")}
                    allowSorting={false}
                    cellRender={imgCellRender}
                    editCellRender={imgEditCellRender}
                  />
                </DataGrid>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.data.token,
  };
};

BannerContainer.propTypes = {
  token: PropsType.string,
};

export default connect(mapStateToProps, null)(BannerContainer);
