import React, { useState } from "react";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import PropsType from "prop-types";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  RequiredRule,
  CompareRule,
} from "devextreme-react/form";
import dictionary from "../../data/dictionary";
import tranformer from "../../utils/transformer";
import { BASE_URL } from "../../utils/constants";

loadMessages(dictionary);
locale("vi");

UpdatePasswordForm.propTypes = {
  token: PropsType.string,
};

export default function UpdatePasswordForm({ token }) {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const updatePassword = () => {
    tranformer.fetchUpdate(`${BASE_URL}profile/password`, {
      token,
      body: {
        password,
        newPassword,
      },
      callback: () => {},
    });
  };

  const handleSubmit = (e) => {
    updatePassword();

    e.preventDefault();
  };

  const buttonOptions = {
    text: "Update",
    type: "success",
    useSubmitBehavior: true,
  };

  return (
    <Card>
      <CardHeader color="blue" contentPosition="left">
        <div className="w-full flex items-center justify-between">
          <h2 className="text-white text-2xl">Cập nhật mật khẩu</h2>
        </div>
      </CardHeader>

      <CardBody>
        <form action="pass-action" onSubmit={handleSubmit}>
          <Form id={"form"} labelLocation={"left"}>
            <SimpleItem
              editorType="dxTextBox"
              editorOptions={{
                mode: "password",
                value: password,
                onValueChanged: (args) => setPassword(args.value),
              }}
            >
              <Label text={formatMessage("password")} />
              <RequiredRule />
            </SimpleItem>

            <SimpleItem
              editorType="dxTextBox"
              editorOptions={{
                mode: "password",
                value: newPassword,
                onValueChanged: (args) => setNewPassword(args.value),
              }}
            >
              <Label text={formatMessage("newPassword")} />
              <RequiredRule />
            </SimpleItem>

            <SimpleItem
              editorType="dxTextBox"
              editorOptions={{ mode: "password" }}
            >
              <Label text={formatMessage("confirmPassword")} />
              <RequiredRule />
              <CompareRule
                message="Mật khẩu mới chưa trùng khớp"
                comparisonTarget={() => {
                  return newPassword;
                }}
              />
            </SimpleItem>

            <ButtonItem
              horizontalAlignment="center"
              buttonOptions={buttonOptions}
            />
          </Form>
        </form>
      </CardBody>
    </Card>
  );
}
