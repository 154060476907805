const UserVM = (data) => {
  console.log("data", data);
  return {
    email: data.email,
    fullName: data.fullName,
    avatar: data.avatar,
    screenName: data.screenName,
  };
};

export { UserVM };
