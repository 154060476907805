import React from "react";
import { connect } from "react-redux";
import Content from "./routes/Content";
import UnAuthenticatedContent from "./routes/UnAuthenticatedContent";
import PropsType from "prop-types";

function App({ isAuthenticated, user }) {
  if (isAuthenticated === true) {
    return <Content roles={user.roles || []} />;
  }

  if (isAuthenticated === false) {
    return <UnAuthenticatedContent />;
  }

  return <div></div>;
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.data.isAuthenticated,
    user: state.user.data,
  };
};

App.propTypes = {
  isAuthenticated: PropsType.bool,
  user: PropsType.object,
};

export default connect(mapStateToProps, null)(App);
