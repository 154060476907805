import React, { useRef } from "react";
import { connect } from "react-redux";
import PropsType from "prop-types";
import {
  DataGrid,
  Column,
  Pager,
  Paging,
  FilterRow,
  FilterPanel,
  Grouping,
  RequiredRule,
  Lookup,
} from "devextreme-react/data-grid";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../data/dictionary";
import { BASE_URL, FILE_URL, VERSION } from "../utils/constants";
import { useHistory } from "react-router-dom";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Button from "@material-tailwind/react/Button";
import useCustomStore from "../hook/useCustomStore";
import useFetch from "../hook/useFetch";

const allowedPageSizes = [10, 20, "all"];
loadMessages(dictionary);
locale("vi");

const URL = `${BASE_URL}${VERSION}post`;
const feedbacksData = useCustomStore(URL, {
  key: "id",
});

const FeedbackContainer = (props) => {
  const history = useHistory();
  const dataGridRef = useRef(null);
  feedbacksData.setOptions({
    token: props.token,
    filter: { group: { id: { in: [21, 22, 23] } } },
    include: ["thumbnail", "group"],
  });

  const groups = useFetch(`${BASE_URL}${VERSION}group`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${props.token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });

  const onToolbarPreparing = (e) => {
    const toolbarItems = e.toolbarOptions.items;

    toolbarItems.unshift({
      location: "after",
      widget: "dxButton",
      locateInMenu: "auto",
      options: {
        icon: "refresh",
        onClick: () => dataGridRef.current.instance.refresh(),
      },
    });
  };

  const imgCellRender = (data) => {
    if (!data.value) {
      return <div></div>;
    }
    return <img style={{ height: 30 }} src={`${FILE_URL}${data.value.path}`} />;
  };

  const onRowDblClick = (e) => {
    history.push(`/feedback/${e.key}`);
  };

  return (
    <>
      <div className="bg-light-blue-500 px-3 md:px-8 h-40" />

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <Card>
              <CardHeader color="purple" contentPosition="none">
                <div className="w-full flex items-center justify-between">
                  <h2 className="text-white text-2xl">Ý kiến khách hàng</h2>
                  <Button
                    color="transparent"
                    buttonType="link"
                    size="lg"
                    style={{ padding: 0 }}
                    onClick={() => history.push(`/feedback/add`)}
                  >
                    Thêm mới
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <DataGrid
                  id="grid"
                  keyExpr="id"
                  ref={dataGridRef}
                  showBorders={true}
                  dataSource={feedbacksData.store}
                  repaintChangesOnly={true}
                  selection={{ mode: "single" }}
                  allowColumnResizing={true}
                  onRowDblClick={onRowDblClick}
                  onToolbarPreparing={onToolbarPreparing}
                >
                  <Grouping autoExpandAll={false} />
                  <FilterRow visible={true} applyFilter={true} />
                  <FilterPanel visible={true} />

                  <Paging defaultPageSize={20} />
                  <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={"full"}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />

                  <Column dataField="title" caption={formatMessage("title")} />
                  <Column
                    dataField="content"
                    caption={formatMessage("content")}
                    editorOptions={{ readOnly: true }}
                  />
                  <Column
                    dataField="groupId"
                    caption={formatMessage("group")}
                    groupIndex={0}
                  >
                    <RequiredRule />
                    <Lookup
                      dataSource={groups.data}
                      valueExpr="id"
                      displayExpr="name"
                    />
                  </Column>
                  <Column
                    dataField="meta.link"
                    caption={formatMessage("link")}
                  />
                  <Column
                    dataField="thumbnail"
                    caption={formatMessage("thumbnail")}
                    allowSorting={false}
                    cellRender={imgCellRender}
                  />
                  <Column
                    dataField="updatedAt"
                    caption={formatMessage("updatedAt")}
                    dataType="datetime"
                  />
                </DataGrid>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.data.token,
    lang: state.setting.language.currentLang,
  };
};

FeedbackContainer.propTypes = {
  token: PropsType.string,
  lang: PropsType.string,
};

export default connect(mapStateToProps, null)(FeedbackContainer);
