import React, { useState, useEffect } from "react";
import Card from "@material-tailwind/react/Card";
import Image from "@material-tailwind/react/Image";
import H5 from "@material-tailwind/react/Heading5";
import Icon from "@material-tailwind/react/Icon";
import PropsType from "prop-types";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import Form, { SimpleItem, Label, ButtonItem } from "devextreme-react/form";
import dictionary from "../../data/dictionary";
import { FILE_URL } from "../../utils/constants";
import CropImage from "../image/CropImage";

loadMessages(dictionary);
locale("vi");

ProfileCard.propTypes = {
  user: PropsType.object,
  token: PropsType.string,
  updateProfile: PropsType.func,
};

export default function ProfileCard({ user, token, updateProfile }) {
  const [userInfo, setUserInfo] = useState({ avatar: { path: "" } });
  useEffect(() => {
    setUserInfo(user);
  }, [user]);

  const handleSubmit = (e) => {
    updateProfile(token, userInfo);

    e.preventDefault();
  };

  const buttonOptions = {
    text: "Update",
    type: "success",
    useSubmitBehavior: true,
  };

  return (
    <Card>
      <div className="flex flex-wrap justify-center">
        <div className="w-48 px-4 -mt-24">
          <Image
            src={`${FILE_URL}${userInfo.avatar ? userInfo.avatar.path : ""}`}
            rounded
            raised
          />
        </div>
      </div>
      <div className="text-center">
        <H5 color="gray">{user.fullName}</H5>
        <div className="mt-0 mb-2 text-gray-700 flex items-center justify-center gap-2">
          <Icon name="email" size="xl" />
          {user.email}
        </div>
      </div>
      <form action="your-action" onSubmit={handleSubmit}>
        <Form id={"form"} formData={userInfo} labelLocation={"left"}>
          <SimpleItem dataField={"fullName"} editorType="dxTextBox">
            <Label text={formatMessage("fullName")} />
          </SimpleItem>

          <SimpleItem dataField={"email"} editorType="dxTextBox">
            <Label text={formatMessage("email")} />
          </SimpleItem>

          <SimpleItem dataField={"username"} editorOptions={{ readOnly: true }}>
            <Label text={formatMessage("username")} />
          </SimpleItem>

          <SimpleItem dataField={"screenName"} editorType="dxTextBox">
            <Label text={formatMessage("screenName")} />
          </SimpleItem>
          <SimpleItem>
            <Label text={formatMessage("avatar")} />
            <CropImage
              token={token}
              onImgChange={(value) =>
                setUserInfo({ ...userInfo, avatar: value })
              }
            />
          </SimpleItem>

          <ButtonItem
            horizontalAlignment="center"
            buttonOptions={buttonOptions}
          />
        </Form>
      </form>
    </Card>
  );
}
