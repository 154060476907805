import React from "react";
import { DataGrid, Column } from "devextreme-react/data-grid";
import PropsType from "prop-types";
import CustomStore from "devextreme/data/custom_store";
import { BASE_URL, VERSION } from "../../utils/constants";
import { loadMessages, formatMessage, locale } from "devextreme/localization";
import dictionary from "../../data/dictionary";
import useFetch from "../../hook/useFetch";

var qs = require("qs");
loadMessages(dictionary);
locale("vi");

function OrderProductsList({ data, token }) {
  console.log("data", data);

  let customer = {};
  if (data.data.customerId) {
    customer = useFetch(
      `${BASE_URL}${VERSION}customer/${data.data.customerId}?${qs.stringify({
        include: ["address"],
      })}`,
      {
        method: "get",
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
  }

  const loadRequest = () => {
    let filter = { orderId: data.key };
    let include = ["product"];

    return fetch(
      `${BASE_URL}${VERSION}orderItem?${qs.stringify({
        filter,
        include,
      })}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
    ).then((result) =>
      result.json().then((json) => {
        if (result.ok) {
          return json.data;
        }
        throw json.Message;
      })
    );
  };

  const productsList = new CustomStore({
    key: "id",
    load: () => loadRequest(),
  });

  const addressTxt =
    customer.data && customer.data.address
      ? customer.data.address.detail
      : null;

  return (
    <>
      {addressTxt && (
        <div
          style={{
            padding: "0 0px 10px 10px",
            fontSize: "14px",
          }}
        >
          <b>Địa chỉ giao hàng: </b>
          {addressTxt} <br />
          <b>Ghi chú: </b>
          {data.data.note}
        </div>
      )}

      <div
        style={{
          padding: "0 0 5px 10px",
          fontSize: "14px",
          fontWeight: "bold",
        }}
      >{`Danh sách Sản phẩm`}</div>

      <DataGrid
        dataSource={productsList}
        showBorders={true}
        columnAutoWidth={true}
      >
        <Column dataField="product.name" caption={formatMessage("name")} />
        <Column dataField="product.sku" caption={formatMessage("sku")} />
        <Column dataField="quantity" caption={formatMessage("quantity")} />

        <Column
          dataField="grossRevenue"
          dataType="number"
          format={{ style: "currency", currency: "VND" }}
          caption={formatMessage("grossRevenue")}
        />
        <Column
          dataField="couponAmount"
          dataType="number"
          format={{ style: "currency", currency: "VND" }}
          caption={formatMessage("couponAmount")}
        />
        <Column
          dataField="netRevenue"
          dataType="number"
          format={{ style: "currency", currency: "VND" }}
          caption={formatMessage("netRevenue")}
        />
      </DataGrid>
    </>
  );
}

OrderProductsList.propTypes = {
  data: PropsType.object,
  token: PropsType.string,
};

export default OrderProductsList;
