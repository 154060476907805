import { PRODUCT_TYPE } from "../utils/constants";

const CategoryVM = (data) => {
  const returnData = Object.assign({}, data);

  if (!data.thumbnail) {
    returnData.thumbnail = {};
  }
  if (!data.meta) {
    returnData.meta = [];
  }

  returnData.metaTitle = data.meta
    ? Object.values(data.meta)
        .map((x) => x.title)
        .join(", ")
    : "";
  returnData.typeName = data.type
    ? PRODUCT_TYPE.find((x) => x.value === data.type).name
    : "";

  return returnData;
};

const CategoryMV = (data) => {
  return {
    name: data.name,
    meta: data.meta,
    type: data.type,
    key: data.key,
    show: data.show,
    parent: { id: data.parentId },
    thumbnail: data.thumbnail,
    insurance: data.insurance,
    userManual: data.userManual,
  };
};

export { CategoryVM, CategoryMV };
