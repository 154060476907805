import React from "react";
import { DataGrid, Column } from "devextreme-react/data-grid";
import PropTypes from "prop-types";

function CouponsView({ data }) {
  const { coupons } = data.row.data;

  return (
    <React.Fragment>
      <div>Danh sách Mã</div>
      <DataGrid dataSource={coupons} showBorders={true} columnAutoWidth={true}>
        <Column dataField="code" />
        <Column dataField="type" />
        <Column dataField="isPercent" dataType="boolean" />
        <Column dataField="customerType" />
        <Column dataField="productType" />
      </DataGrid>
    </React.Fragment>
  );
}

CouponsView.propTypes = {
  data: PropTypes.object,
};

export default CouponsView;
