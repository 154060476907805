import React from 'react';
import HtmlEditor, { Toolbar, Item, MediaResizing } from 'devextreme-react/html-editor';
import '../styles/htmleditor.scss';
import PropsType from 'prop-types';

const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = ['Arial', 'Georgia', 'Tahoma', 'Times New Roman', 'Verdana'];
const headerValues = [false, 1, 2, 3, 4, 5];

const HTMLEditor = ({ defaultValue = '', onValueChange, height = '700px' }) => {
    return (
        <div className="widget-container">
            <HtmlEditor
                height={height}
                defaultValue={defaultValue}
                onValueChange={onValueChange}
                placeholder={'Nội dung bài viết'}
            >
                <MediaResizing enabled={true} />
                <Toolbar multiline={false}>
                    <Item name="undo" />
                    <Item name="redo" />
                    <Item name="separator" />
                    <Item
                        name="size"
                        acceptedValues={sizeValues}
                    />
                    <Item
                        name="font"
                        acceptedValues={fontValues}
                    />
                    <Item name="separator" />
                    <Item name="bold" />
                    <Item name="italic" />
                    <Item name="strike" />
                    <Item name="underline" />
                    <Item name="separator" />
                    <Item name="alignLeft" />
                    <Item name="alignCenter" />
                    <Item name="alignRight" />
                    <Item name="alignJustify" />
                    <Item name="separator" />
                    <Item name="orderedList" />
                    <Item name="bulletList" />
                    <Item name="separator" />
                    <Item
                        name="header"
                        acceptedValues={headerValues}
                    />
                    <Item name="separator" />
                    <Item name="color" />
                    <Item name="background" />
                    <Item name="separator" />
                    <Item name="link" />
                    <Item name="image" />
                    <Item name="separator" />
                    <Item name="clear" />
                    <Item name="codeBlock" />
                    <Item name="blockquote" />
                    <Item name="separator" />
                    <Item name="insertTable" />
                    <Item name="deleteTable" />
                    <Item name="insertRowAbove" />
                    <Item name="insertRowBelow" />
                    <Item name="deleteRow" />
                    <Item name="insertColumnLeft" />
                    <Item name="insertColumnRight" />
                    <Item name="deleteColumn" />
                </Toolbar>
            </HtmlEditor>
        </div>
    );
};

HTMLEditor.propTypes = {
    defaultValue: PropsType.string,
    onValueChange: PropsType.func,
    height: PropsType.any
};

export default HTMLEditor;
