import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
// import history from "../history";

var qs = require("qs");
var unflatten = require("flat").unflatten;

function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== "";
}

function convertOperator(operator) {
  switch (operator) {
    case ">":
      return "gt";
    case ">=":
      return "gte";
    case "<":
      return "lt";
    case "<=":
      return "lte";

    case "startswith":
      return "startsWith";
    case "endswith":
      return "endsWith";
    case "=":
      return "equals";
    case "<>":
      return "not";
    case "!":
      return "not";

    default:
      return operator;
  }
}

function processFilter(filter) {
  if (Array.isArray(filter)) {
    const _length = filter.length;
    if (_length === 2) {
      return {
        [convertOperator(filter[0]).toUpperCase()]: processFilter(filter[1]),
      };
    } else if (_length % 2 === 1) {
      if (!Array.isArray(filter[0])) {
        return { [filter[0]]: { [convertOperator(filter[1])]: filter[2] } };
      } else {
        const _returnFilter = [];
        filter.forEach((element) => {
          if (Array.isArray(element)) {
            _returnFilter.push(processFilter(element));
          }
        });
        return {
          [filter[1].toUpperCase()]: _returnFilter,
        };
      }
    } else {
      console.log("Chưa xác định case này!");
    }
  }
}

export default function useCustomStore(url, defaultOptions) {
  let options = defaultOptions;

  const loadRequest = (loadOptions) => {
    if (!options.token) {
      return;
    }
    // console.log("loadOptions", loadOptions);

    let paramsObj = {};

    paramsObj["skip"] = loadOptions.skip || 0;
    paramsObj["take"] = loadOptions.take || options.take || 20;

    let _filter = {};

    if (isNotEmpty(loadOptions.filter)) {
      _filter = unflatten(processFilter(loadOptions.filter));
      // console.log(processFilter(loadOptions.filter));
    }

    if (isNotEmpty(loadOptions.sort)) {
      paramsObj["sort"] = [];
      loadOptions.sort.forEach((element) => {
        paramsObj["sort"].push({
          [element.selector]: element.desc ? "desc" : "asc",
        });
      });
    }

    paramsObj["filter"] = { ..._filter, ...options.filter };
    paramsObj["include"] = options.include;
    paramsObj = { ...paramsObj, ...options.defaultParams };

    // console.log("paramsObj", paramsObj);

    return fetch(`${url}?${qs.stringify(paramsObj)}`, {
      method: "get",
      headers: {
        Authorization: `Bearer ${options.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data.data,
          totalCount: data.meta.page.total,
        }))
        .catch((err) => {
          if (response.status === 401) {
            options.history && options.history.push("/expiredToken");
          }
          throw new Error(err);
        })
    );
  };

  const byKey = (key) => {
    return fetch(`${url}/${key}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${options.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => data.data);
  };

  function setOptions(updateOptions) {
    options = { ...options, ...updateOptions };
  }

  const store = new CustomStore({
    key: options.key,
    byKey: options.byKey || byKey,
    load: options.load || loadRequest,
    update: (key, values) => {
      return fetch(`${url}/${key}`, {
        method: "PUT",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${options.token}`,
        },
      }).then((result) => {
        if (result.ok) {
          notify(
            {
              message: "Updated successfully",
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );

          return result
            .text()
            .then((text) => text && { ...values, ...JSON.parse(text).data });
        } else {
          return result.json().then((json) => {
            throw json.Message;
          });
        }
      });
    },
    insert: (values) => {
      return fetch(`${url}`, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${options.token}`,
        },
      }).then((result) => {
        if (result.ok) {
          notify(
            {
              message: "Added successfully",
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );

          return result
            .text()
            .then((text) => text && { ...values, ...JSON.parse(text).data });
        } else {
          return result.json().then((json) => {
            throw json.Message;
          });
        }
      });
    },
    remove: (key) => {
      return fetch(`${url}/${key}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${options.token}`,
        },
      }).then((result) => {
        if (result.ok) {
          notify(
            {
              message: "Deleted successfully",
              position: {
                my: "center top",
                at: "center top",
              },
            },
            "success",
            3000
          );

          return result.text().then((text) => text && JSON.parse(text).data);
        } else {
          return result.json().then((json) => {
            throw json.Message;
          });
        }
      });
    },
  });

  return { store, setOptions };
}
