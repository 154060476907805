import React, { useEffect, useState } from "react";
import Form, {
  ButtonItem,
  GroupItem,
  SimpleItem,
  RequiredRule,
} from "devextreme-react/form";
import { connect } from "react-redux";
import { login } from "../actions/authActions";
import PropsType from "prop-types";
import { useHistory } from "react-router-dom";
import "../styles/login.scss";

const LoginContainer = (props) => {
  const [loginForm] = useState({
    username: "",
    password: "",
    grant_type: "password",
  });
  const history = useHistory();

  const passwordOptions = {
    mode: "password",
  };
  const buttonOptions = {
    text: "Login",
    type: "success",
    useSubmitBehavior: true,
  };

  const handleSubmit = (e) => {
    props.login(loginForm);

    e.preventDefault();
  };

  useEffect(() => {
    if (props.isAuthenticated) {
      history.push("/");
    }
  }, [props.isAuthenticated]);

  return (
    <React.Fragment>
      <form className="login-form" action="your-action" onSubmit={handleSubmit}>
        <Form
          formData={loginForm}
          readOnly={false}
          showColonAfterLabel={true}
          showValidationSummary={true}
        >
          <GroupItem caption="Đăng nhập">
            <SimpleItem dataField="username" editorType="dxTextBox">
              <RequiredRule message="Username is required" />
            </SimpleItem>
            <SimpleItem
              dataField="password"
              editorType="dxTextBox"
              editorOptions={passwordOptions}
            >
              <RequiredRule message="Password is required" />
            </SimpleItem>
          </GroupItem>

          <ButtonItem
            horizontalAlignment="left"
            buttonOptions={buttonOptions}
          />
        </Form>
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.data.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (username, password) => {
      return dispatch(login(username, password));
    },
  };
};

LoginContainer.propTypes = {
  login: PropsType.func,
  isAuthenticated: PropsType.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
