const PostVM = (data) => {
  const returnData = Object.assign({}, data);
  returnData.tags = data.tags ? data.tags.map((x) => x.id) : [];

  return returnData;
};

const PostMV = (data) => {
  return {
    title: data.title,
    slug: data.slug,
    excerpt: data.excerpt,
    content: data.content,
    status: data.status || 'publish',
    group: data.groupId ? { id: data.groupId } : undefined,
    thumbnail: data.thumbnail ? { id: data.thumbnail.id } : undefined,
    tags: data.tags
      ? data.tags.map((x) => {
        return { id: x };
      })
      : undefined,
  };
};

export { PostVM, PostMV };
