import tranformer from "../utils/transformer";

const ProductVM = (data) => {
  const returnData = Object.assign({}, data);
  if (!data.thumbnail) {
    returnData.thumbnail = {};
  }
  if (!data.category) {
    returnData.category = { meta: {} };
  }
  if (!data.meta) {
    returnData.meta = [];
  }
  if (data.groups) {
    returnData.groupId = data.groups.map((x) => x.id);
  }

  returnData.categoryName = data.category ? data.category.name : "";
  returnData.brandName = data.brand ? data.brand.name : "";

  return returnData;
};

const ProductMV = (data) => {
  return {
    sku: data.sku.trim(),
    name: data.name,
    slug: data.slug.trim(),
    type: data.type,
    salePrice: data.salePrice,
    price: data.price,
    description: data.description,
    category: data.categoryId ? { id: data.categoryId } : undefined,
    brand: data.brandId ? { id: data.brandId } : undefined,
    thumbnail: data.thumbnail ? { id: data.thumbnail.id } : undefined,
    groups:
      tranformer.isNotEmpty(data.groupId) &&
        tranformer.isNotEmptyArr(data.groupId)
        ? data.groupId.map((x) => {
          return { id: x };
        })
        : undefined,
    friendlyGroup: data.friendlyGroupId
      ? { id: data.friendlyGroupId }
      : undefined,
  };
};

const combineMeta = (meta, categoryId) => {
  let _meta = { ...meta };

  Object.keys(_meta).forEach((key) => {
    if (!_meta[key]) {
      delete _meta[key];
    }
  });

  switch (categoryId) {
    //đồng hồ
    case 21:
      Object.keys(_meta).forEach((key) => {
        switch (key) {
          case "mau-mat":
            _meta["meta0"] = _meta[key];
            break;

          case "loai-day":
            _meta["meta4"] = _meta[key];
            break;

          case "mau-vo":
            _meta["meta8"] = _meta[key];
            break;

          case "kich-co":
            _meta["meta11"] = _meta[key];
            break;

          default:
            break;
        }
      });
      break;

    //Kính mát
    case 4:
      Object.keys(_meta).forEach((key) => {
        switch (key) {
          case "do-rong-trong":
            _meta["meta7"] = _meta[key];
            break;

          case "mau-sac":
            _meta["meta4"] = _meta[key];
            break;

          case "mat-kinh":
            _meta["meta17"] = _meta[key];
            break;

          case "chieu-dai-gong":
            _meta["meta8"] = _meta[key];
            break;

          case "mau-gong":
            _meta["meta11"] = _meta[key];
            break;

          case "xuat-xu":
            _meta["meta2"] = _meta[key];
            break;

          default:
            break;
        }
      });
      break;

    //Nước hoa
    case 10:
      Object.keys(_meta).forEach((key) => {
        switch (key) {
          case "nhom-huong":
            _meta["meta4"] = _meta[key];
            break;

          case "xuat-xu":
            _meta["meta2"] = _meta[key];
            break;

          case "dung-tich":
            _meta["meta8"] = _meta[key];
            break;

          default:
            break;
        }
      });
      break;

    //Trang sức
    case 11:
      Object.keys(_meta).forEach((key) => {
        switch (key) {
          case "chung-loai":
            _meta["meta1"] = _meta[key];
            break;

          case "vat-lieu":
            _meta["meta2"] = _meta[key];
            break;

          case "loai-da":
            _meta["meta3"] = _meta[key];
            break;

          default:
            break;
        }
      });
      break;

    default:
      break;
  }

  return _meta;
};

const ProductExcelMV = (data) => {
  const {
    sku,
    type,
    categoryId,
    name,
    slug,
    salePrice,
    price,
    brandId,
    ...meta
  } = data;

  const _meta = combineMeta(meta, categoryId);

  return {
    sku,
    name,
    slug,
    type,
    salePrice,
    price,
    categoryId,
    brandId,
    meta: Object.keys(_meta).map((x) => ({ key: x, value: _meta[x] })),
  };
};

export { ProductVM, ProductMV, ProductExcelMV };
