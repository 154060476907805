export const HOST = "https://doseco.vn/";
export const BASE_URL = "https://doseco.vn/api/";
export const FILE_URL = "https://doseco.vn/media/";

// export const HOST = "http://doseco.vj-soft.com/";
// export const BASE_URL = "http://doseco.vj-soft.com/api/";
// export const FILE_URL = "http://doseco.vj-soft.com/media/";

export const VERSION = "v2/";

export const pages = [
  { id: 1, name: "home" },
  { id: 2, name: "product-detail" },
];

export const languages = [
  { id: 1, name: "vi", value: "Tiếng Việt" },
  { id: 2, name: "en", value: "English" },
];

export const CATEGORY_TYPE = [
  {
    name: "Lựa chọn",
    value: "array",
  },
  {
    name: "Nhập số",
    value: "number",
  },
  {
    name: "Nhập text",
    value: "string",
  },
];

export const THEMES_LIST = [
  { value: "dx.dark.css", name: "Dark" },
  { value: "dx.light.css", name: "Light" },
  { value: "dx.darkviolet.css", name: "DarkViolet" },
  { value: "dx.carmine.css", name: "Carmine" },
  { value: "dx.darkmoon.css", name: "Darkmoon" },
  { value: "dx.greenmist.css", name: "GreenMist" },
  { value: "dx.contrast.css", name: "Contrast" },
  { value: "dx.softblue.css", name: "SoftBlue" },
];

export const PRODUCT_COMMENT_TYPE = 1;

export const FRIENDLY_GROUP_TYPE = 11;
export const FEEDBACK_GROUP_TYPE = 10;
export const POST_GROUP_TYPE = 9; //Bài viết
export const GUIDE_GROUP_TYPE = 8; //Hướng dẫn mua hàng

export const PRODUCT_GROUP_ID = 12;
export const FRIENDLY_GROUP_ID = 26;

export const PRODUCT_TYPE = [
  { id: 1, name: "Đồng hồ", value: 1 },
  { id: 2, name: "Kính mát", value: 2 },
  { id: 3, name: "Trang sức", value: 3 },
  { id: 4, name: "Nước hoa", value: 4 },
  { id: 5, name: "Phụ kiện", value: 5 },
  { id: 6, name: "Túi ví", value: 6 },
];

export const GROUP_TYPE = [
  { id: 1, name: "Đồng hồ", value: 1 },
  { id: 2, name: "Kính mát", value: 2 },
  { id: 3, name: "Trang sức", value: 3 },
  { id: 4, name: "Nước hoa", value: 4 },
  { id: 5, name: "Phụ kiện", value: 5 },
  { id: 6, name: "Túi ví", value: 6 },
  {
    id: FRIENDLY_GROUP_TYPE,
    name: "Friendly Group",
    value: FRIENDLY_GROUP_TYPE,
  },
  {
    id: FEEDBACK_GROUP_TYPE,
    name: "Feedback Group",
    value: FEEDBACK_GROUP_TYPE,
  },
];

export const GROUP_FILTER_TYPE = [
  1,
  2,
  3,
  4,
  5,
  6,
  // 7,
  // 8,
  // 9,
  FEEDBACK_GROUP_TYPE, //10
  FRIENDLY_GROUP_TYPE, //11
];

export const POST_FILTER = [
  { id: 1, name: "Hướng dẫn mua hàng", value: GUIDE_GROUP_TYPE },
  { id: 2, name: "Bài viết", value: POST_GROUP_TYPE },
];

export const GROUP_FILTER = [
  { id: 1, name: "Nhóm sản phẩm", value: PRODUCT_TYPE.map((x) => x.value) },
  { id: 2, name: "Nhóm Friendly", value: FRIENDLY_GROUP_TYPE },
  { id: 3, name: "Nhóm Feedback", value: FEEDBACK_GROUP_TYPE },
];

export const STATUS = [
  { id: 1, name: "Còn hàng", value: 1 },
  { id: 2, name: "Hà Nội", value: 2 },
  { id: 3, name: "Tp.HCM", value: 3 },
  { id: 4, name: "Order 7-10 ngày", value: 4 },
  { id: 5, name: "Order 20-30 ngày", value: 5 },
  { id: 6, name: "Liên hệ", value: 6 },
];

export const ORDER_STATUS = [
  { id: 1, name: "Order", value: 1 },
  { id: 2, name: "In Processing", value: 2 },
  { id: 3, name: "Shipping", value: 3 },
  { id: 4, name: "Complete", value: 4 },
  { id: 5, name: "Cancel", value: 5 },
];

export const POST_STATUS = [
  { value: 'publish', name: 'Công khai' },
  { value: 'draft', name: 'Lưu nháp' },
]

export const USER_ROLE = [
  { id: 1, role: "administrator", name: "Administrator" },
  { id: 2, role: "product-manager", name: "Product Manager" },
  { id: 3, role: "post-editor", name: "Post Editor" },
  { id: 4, role: "order-manager", name: "Order Manager" },
  { id: 5, role: "doseco-user", name: "Khách hàng" },
  { id: 6, role: "marketing", name: "Marketing" },
];

export const PAGE_NAME = {
  banner: "Banner",
  brand: "Thương hiệu",
  category: "Danh mục",
  comment: "Comment",
  coupon: "Khuyến mại",
  group: "Nhóm",
  order: "Đơn hàng",
  payment: "Thanh toán",
  post: "Bài viết",
  product: "Sản phẩm",
  setting: "Cài đặt",
  user: "User",
  video: "Video",
};

export const PERMISSION = {
  "cms:view": "CMS View",
  "cms:edit": "CMS Edit",
  "cms:delete": "CMS Delete",
  "web:view": "Web View",
  "cms:change_password": "CMS Change Password",
  "cms:update_profile": "CMS Update Profile",
  "web:change_password": "Web Change Password",
  "web:view_profile": "Web View Profile",
  "web:update_profile": "Web Update Profile",
  "web:comment": "Web Comment",
  "web:add_order": "Web Add Order",
  "web:my_order": "Web My Order",
};

export const SIZE_VALUES = [
  "8pt",
  "10pt",
  "12pt",
  "14pt",
  "18pt",
  "24pt",
  "36pt",
];

export const FONT_VALUES = [
  "Arial",
  "Courier New",
  "Georgia",
  "Impact",
  "Lucida Console",
  "Tahoma",
  "Times New Roman",
  "Verdana",
];

export const HTML_ITEMS = [
  { name: "size", acceptedValues: SIZE_VALUES },
  { name: "font", acceptedValues: FONT_VALUES },
  "separator",
  "bold",
  "italic",
  "strike",
  "underline",
  "separator",
  "alignLeft",
  "alignCenter",
  "alignRight",
  "alignJustify",
  "separator",
  "orderedList",
  "bulletList",
  "insertTable",
  "deleteTable",
  "insertRowAbove",
  "insertRowBelow",
  "deleteRow",
  "insertColumnLeft",
  "insertColumnRight",
  "deleteColumn",
  "separator",
  "link",
  "image",
];

export const COUPON_TYPE = [
  {
    value: "All",
    name: "Tất cả",
    fullTxt: "Áp dụng cho tất cả KH, SP và PTTT",
  },
  {
    value: "OrderPrice",
    name: "Theo giá",
    fullTxt: "KM theo giá trị của đơn hàng",
  },
  { value: "Product", name: "Theo SP", fullTxt: "KM theo sản phẩm được mua" },
  {
    value: "Customer",
    name: "Theo KH",
    fullTxt: "KM theo đối tượng khách hàng",
  },
  {
    value: "PaymentMethod",
    name: "Theo PTTT",
    fullTxt: "KM theo phương thức thanh toán",
  },
];

export const COUPON_CUSTOMER_TYPE = [
  { value: "all", name: "Tất cả KH" },
  { value: "new", name: "KH mới" },
  { value: "old", name: "KH cũ" },
];

export const COUPON_PRODUCT_TYPE = [
  { value: "individual", name: "Áp dụng cho từng SP" },
  { value: "all", name: "Áp dụng cho tất cả SP" },
  { value: "category", name: "Theo danh mục" },
  { value: "group", name: "Theo nhóm" },
];

export const DISCOVERY_DOSECO = "kham-pha-doseco";